import messages from '../messages';
import { Input } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import React from 'react';
import useIntl from '../../../hooks/useIntl';

const RentForecastModal = (props) => {
  const {
    open,
    closeModal,
    name,
    handleNameChange,
    createRentForecast,
    isLoading,
  } = props;
  const intl = useIntl();

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={intl.formatMessage(messages.newRentForecast)}
      actionsProps={[
        {
          children: 'Cancel',
          onClick: closeModal,
        },
        {
          children: 'Save',
          submitButton: true,
          isSubmitting: isLoading,
          onClick: createRentForecast,
          disabled: !name,
        },
      ]}
      ModalProps={{
        PaperProps: {
          sx: {
            width: {
              xs: 'calc(100% - 32px)',
              sm: 480,
            },
            maxHeight: 'calc(100vh - 100px)',
          },
        },
      }}
    >
      <Input
        label={intl.formatMessage(messages.name)}
        placeholder={intl.formatMessage(messages.placeholder)}
        onChange={handleNameChange}
        required
        value={name}
      />
    </Modal>
  );
};

export default RentForecastModal;
