export const PROPERTY_PATH_MAP = {
  floorPlan: {
    path: 'floorPlan',
    dataType: 'string',
  },
  bedsBaths: {
    path: 'bedsBaths',
    dataType: 'string',
  },
  numUnits: {
    path: 'numUnits',
    dataType: 'number',
  },
  currentBasicRent: {
    path: 'currentBasicRent',
    dataType: 'string',
  },
  currentNoteRent: {
    path: 'currentNoteRent',
    dataType: 'string',
  },
};
