import { isEmpty, isNil, type, pathOr } from 'ramda';
import moment from 'moment';
import messages from './messages';
import { validateEmail, validatePhoneNumber } from '../../../utils/validations';

type StatusText = 'Completed' | 'In Progress' | 'On Hold' | 'Open';

export const validateEmailString = (intl: Object, email: string) => {
  if (!validateEmail(email)) {
    return intl.formatMessage(messages.invalidEmailAddress);
  }
};

export const validateFinisheDate = (
  intl: Object,
  date: Object,
  statuses: Array<Object>,
  statusId: string,
  createdAt: Object,
) => {
  if (statuses.length < 2) return;
  const completedStatus = statuses.find(
    (status) => status.text === 'Completed',
  );

  // $FlowFixMe
  const needCheck = completedStatus.value === statusId;
  const dateM = moment(date).add(1, 'day');
  const createdAtM = moment(createdAt);
  if (needCheck && (!dateM.isValid() || type(date) === 'String')) {
    return intl.formatMessage(messages.invalidDateFormat);
  }
  if (needCheck && dateM.isBefore(createdAtM)) {
    return intl.formatMessage(messages.invalidDateFromPreferenceValue);
  }
};

export const validatePhoneNumberString = (
  intl: Object,
  phoneNumber: string,
) => {
  if (!validatePhoneNumber(phoneNumber)) {
    return intl.formatMessage(messages.invalidPhoneNumber);
  }
};

export const validateReportedDateFormat = (intl: Object, date: Object) => {
  const formattedDate = moment(date);
  const todayDate = moment();
  if (!formattedDate.isValid() || type(date) === 'String') {
    return intl.formatMessage(messages.invalidDateFormat);
  } else if (formattedDate.isAfter(todayDate)) {
    return intl.formatMessage(messages.invalidReportedDateValue);
  }
};

export const validateDatePreferenceFrom = (
  intl: Object,
  date: Object,
  isEditMode: boolean,
) => {
  const formattedDate = moment(date);
  const todayDate = moment().subtract(1, 'day');
  if (!formattedDate.isValid() || type(date) === 'String') {
    return intl.formatMessage(messages.invalidDateFormat);
  } else if (formattedDate.isBefore(todayDate) && !isEditMode) {
    return intl.formatMessage(messages.invalidDateFromPreferenceValue);
  }
};

export const validateDatePreferenceTo = (
  intl: Object,
  dateFrom: Object,
  dateTo: Object,
) => {
  const formattedDateTo = moment(dateTo);
  const formattedDateFrom = moment(dateFrom);
  if (!formattedDateTo.isValid() || type(dateTo) === 'String') {
    return intl.formatMessage(messages.invalidDateFormat);
  } else if (formattedDateTo.isBefore(formattedDateFrom)) {
    return intl.formatMessage(messages.invalidDateToPreferenceValue);
  }
};

export const validateTimePreferenceFrom = (intl: Object, timeFrom: Object) => {
  const formattedTimeFrom = moment(timeFrom);
  if (!formattedTimeFrom.isValid() || type(timeFrom) === 'String') {
    return intl.formatMessage(messages.invalidTimeFormat);
  }
};

export const validateTimePreferenceTo = (
  intl: Object,
  timeFrom: Object,
  timeTo: Object,
) => {
  const formattedTimeTo = moment(timeTo);
  const formattedTimeFrom = moment(timeFrom);

  /**
   * Convert to total minutes to avoid date-parsing issues.
   */
  const onlyTimeTo = formattedTimeTo.hours() * 60 + formattedTimeTo.minutes();
  const onlyTimeFrom =
    formattedTimeFrom.hours() * 60 + formattedTimeFrom.minutes();

  if (!formattedTimeTo.isValid() || type(timeTo) === 'String') {
    return intl.formatMessage(messages.invalidTimeFormat);
  } else if (onlyTimeFrom > onlyTimeTo) {
    return intl.formatMessage(messages.invalidTimeToPreferenceValue);
  }
};

export const isStatusSelected = (
  statuses: Object[],
  selectedStatusId: string,
  statusToFind: StatusText = 'On Hold',
) => {
  const status = statuses.find((status) => status.value === selectedStatusId);
  let isSelected = false;
  if (status) {
    isSelected = status.text === statusToFind;
  }
  return isSelected;
};

export const validate = (values: Object, props: Object) => {
  const { intl, isEditMode, statuses, commonAreaLocations } = props;
  const errors = {};

  if (isEmpty(values.issueId) || isNil(values.issueId)) {
    errors.issueSearch = intl.formatMessage(messages.requiredField);
  }
  if (values.location === 'unit' && !values.unitId) {
    errors.unitSearch = intl.formatMessage(messages.nonexistentUnit);
  }
  if (
    !values.staffRequested &&
    values.location === 'unit' &&
    !values.unitSearch
  ) {
    errors.unitSearch = intl.formatMessage(messages.requiredField);
  }

  if (values.location === 'unit' && !values.unitLocationId) {
    errors.unitLocationId = ' ';
  }

  if (values.location === 'commonArea' && !values.commonAreaLocationId) {
    errors.commonAreaLocationId = ' ';
  }

  if (values.commonAreaLocationId) {
    const commonArea = commonAreaLocations.find(
      (loc) => loc.value === values.commonAreaLocationId,
    );
    if (
      commonArea.text === 'Other' &&
      !pathOr(null, ['otherCommonAreaLocation'], values)
    ) {
      errors.otherCommonAreaLocation = intl.formatMessage(
        messages.requiredField,
      );
    }
  }

  if (!errors.requestorEmail) {
    errors.requestorEmail = validateEmailString(intl, values.requestorEmail);
  }
  if (!errors.requestorPhone) {
    errors.requestorPhone = validatePhoneNumberString(
      intl,
      values.requestorPhone,
    );
  }
  if (isEmpty(values.requestDate) || isNil(values.requestDate)) {
    errors.requestDate = intl.formatMessage(messages.requiredField);
  }
  if (values.requestDate) {
    errors.requestDate = validateReportedDateFormat(intl, values.requestDate);
  }
  if (values.requestedStartDateFrom) {
    errors.requestedStartDateFrom = validateDatePreferenceFrom(
      intl,
      values.requestedStartDateFrom,
      isEditMode,
    );
  }
  if (values.requestedStartDateTo) {
    errors.requestedStartDateTo = validateDatePreferenceTo(
      intl,
      values.requestedStartDateFrom,
      values.requestedStartDateTo,
    );
  }
  if (values.requestedStartTimeFrom) {
    errors.requestedStartTimeFrom = validateTimePreferenceFrom(
      intl,
      values.requestedStartTimeFrom,
    );
  }
  if (values.requestedStartTimeTo) {
    errors.requestedStartTimeTo = validateTimePreferenceTo(
      intl,
      values.requestedStartTimeFrom,
      values.requestedStartTimeTo,
    );
  }
  if (!isEmpty(values.finishedDate) || !isNil(values.finishedDate)) {
    errors.finishedDate = validateFinisheDate(
      intl,
      values.finishedDate,
      statuses,
      values.statusId,
      values.createdAt,
    );
  }

  if (values.issueNotFound && !values.otherDescription) {
    errors.otherDescription = intl.formatMessage(messages.requiredField);
  }

  if (isStatusSelected(statuses, values.statusId) && !values.holdReason) {
    errors.holdReason = intl.formatMessage(messages.requiredField);
  }

  if (pathOr('', ['otherDescription'], values).length > 500) {
    errors.otherDescription = intl.formatMessage(messages.tooLong);
  }

  if (values.unitIssue) {
    for (const val in values.unitIssue) {
      if (
        values.unitIssue[val] === true &&
        (!values.unitIssueDescription || !values.unitIssueDescription[val])
      ) {
        if (!errors.unitIssueDescription) {
          errors.unitIssueDescription = {};
        }
        errors.unitIssueDescription[val] = intl.formatMessage(
          messages.requiredField,
        );
      }
    }
  }
  errors.petAlerts = {};
  if (values.petAlert) {
    // if the checkbox is selected, make individual pet alerts mandatory
    if (!isEmpty(values.petAlerts) && !isNil(values.petAlerts)) {
      Object.keys(values.petAlerts).forEach((petId) => {
        if (isNil(values.petAlerts[petId].isCaged)) {
          errors.petAlerts[petId] = {
            isCaged: intl.formatMessage(messages.requiredField),
          };
        }
      });
    }
  }

  if (
    values.totalAmountOwedByResident &&
    values.totalCostOfMaterialsUsed &&
    Number(values.totalAmountOwedByResident) >
      Number(values.totalCostOfMaterialsUsed)
  ) {
    errors.totalAmountOwedByResident = intl.formatMessage(
      messages.totalAmountOwedByResidentError,
    );
  }

  errors.serviceLogs = values.serviceLogs?.map((serviceLog) => {
    const timeErrors = {};
    const validServiceDate =
      serviceLog.serviceDate && moment(serviceLog.serviceDate).isValid();

    if (validServiceDate) {
      if (!serviceLog.startTime || !moment(serviceLog.startTime).isValid()) {
        timeErrors.startTime = intl.formatMessage(messages.requiredHelperText);
      }
      if (!serviceLog.endTime || !moment(serviceLog.endTime).isValid()) {
        timeErrors.endTime = intl.formatMessage(messages.requiredHelperText);
      }
      if (serviceLog.startTime && serviceLog.endTime) {
        const startTime = moment(serviceLog.startTime);
        const endTime = moment(serviceLog.endTime);
        if (startTime.isSameOrAfter(endTime)) {
          timeErrors.endTime = intl.formatMessage(
            messages.endTimeBeforeStartTime,
          );
        }
      }
    }

    return timeErrors;
  });

  return errors;
};

export default validate;
