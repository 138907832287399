import { useMemo } from 'react';
import {
  getDateDifference,
  formatDateDB,
} from '@fortress-technology-solutions/fortress-component-library/utils';
import {
  GROSS_RENT_CERT_TYPE,
  OTHER_HUD,
  OTHER,
  RECERT_CERT_TYPE,
  CERTIFICATION_TYPE_DISPLAY_NAMES,
  RD_GROSS_RENT_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';

const useParseResults = ({ results, selectedCertificationType }) => {
  return useMemo(() => {
    return results.map((result) => {
      const parsedResult = { ...result };
      if (
        result.hasOwnProperty('occupiedOnNTV') &&
        ![GROSS_RENT_CERT_TYPE].includes(selectedCertificationType) &&
        ![RD_GROSS_RENT_CERT_TYPE].includes(selectedCertificationType)
      ) {
        parsedResult.occupiedOnNTV = result.occupiedOnNTV ? 'yes' : null;
      }

      if ([OTHER_HUD, OTHER].includes(selectedCertificationType)) {
        if (result.hasOwnProperty('isPriorResident')) {
          parsedResult.isPriorResident = result.isPriorResident ? 'yes' : 'no';
        }
        if (result.hasOwnProperty('certificationType')) {
          parsedResult.certificationType =
            CERTIFICATION_TYPE_DISPLAY_NAMES[result.certificationType] ??
            result.certificationType;
        }
      }

      if (
        result.hasOwnProperty('headOfHousehold') &&
        [RECERT_CERT_TYPE].includes(selectedCertificationType)
      ) {
        // split first and last name, reverse order, add comma
        const headOfHousehold = result.headOfHousehold.split(' ');
        const lastName = headOfHousehold[headOfHousehold.length - 1];
        const firstName = headOfHousehold[0];
        parsedResult.headOfHousehold = `${lastName}, ${firstName}`;
      }

      if (
        result.hasOwnProperty('notice120') ||
        result.hasOwnProperty('notice90') ||
        result.hasOwnProperty('notice60') ||
        result.hasOwnProperty('notice30')
      ) {
        parsedResult.notice120 = result.notice120 === true ? 'Yes' : null;
        parsedResult.notice90 = result.notice90 === true ? 'Yes' : null;
        parsedResult.notice60 = result.notice60 === true ? 'Yes' : null;
        parsedResult.notice30 = result.notice30 === true ? 'Yes' : null;
      }

      if (result.hasOwnProperty('isApplicant')) {
        parsedResult.isResident =
          result.isApplicant === false && result.isPriorResident === false
            ? 'yes'
            : 'no';
      }

      if (result.hasOwnProperty('isCorrection')) {
        parsedResult.isCorrection = result.isCorrection ? 'yes' : 'no';
      }

      if (
        result.hasOwnProperty('latestDocument') &&
        result?.latestDocument?.createdAt
      ) {
        parsedResult.daysSinceLastUpload = getDateDifference(
          formatDateDB(result.latestDocument.createdAt),
        );
      }

      if (result.hasOwnProperty('generated59a')) {
        parsedResult.generated59a = result.generated59a ? 'yes' : null;
      }

      return parsedResult;
    });
  }, [results, selectedCertificationType]);
};

export default useParseResults;
