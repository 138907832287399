import { useMemo } from 'react';

const useRows = ({ sortedAndFilteredResults }) => {
  return useMemo(() => {
    return sortedAndFilteredResults.map((result) => {
      return {
        id: result.id,
        floorPlan: {
          variant: 'link',
          to: result.floorPlanLink,
          value: result.floorPlan,
        },
        bedsBaths: {
          value: result.bedsBaths,
        },
        numUnits: {
          value: result.numUnits,
          variant: 'number',
        },
        currentBasicRent: {
          value: result.currentBasicRent,
          variant: 'currency',
        },
        currentNoteRent: {
          value: result.currentNoteRent,
          variant: 'currency',
        },
      };
    });
  }, [sortedAndFilteredResults]);
};

export default useRows;
