import {
  DatePicker,
  DateRangePicker,
} from '@fortress-technology-solutions/fortress-component-library/Organisms';
import {
  InputLabel,
  Box,
  Radio,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Molecules';

export default function CreateCentralizedReportForm({
  dateRangeStart,
  dateRangeEnd,
  year,
  setDateRangeStart,
  setDateRangeEnd,
  setYear,
  variant,
  isWorkOrderReport,
  dateRangeType,
  setDateRangeType,
}) {
  return (
    <>
      <Box>
        <Box
          sx={{
            marginBottom: 3,
            minWidth: '476px',
          }}
        >
          {isWorkOrderReport && (
            <Stack direction="row" spacing={2} marginBottom={1.5}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Radio
                  sx={{ padding: 0 }}
                  checked={dateRangeType === 'createdAt'}
                  onChange={() => {
                    setDateRangeType('createdAt');
                  }}
                />
                <Typography>Creation Date Range</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Radio
                  sx={{ padding: 0 }}
                  checked={dateRangeType === 'finishedDate'}
                  onChange={() => {
                    setDateRangeType('finishedDate');
                  }}
                />
                <Typography>Completion Date Range</Typography>
              </Stack>
            </Stack>
          )}
          {variant === 'date-range' && (
            <DateRangePicker
              FromProps={{
                name: 'dateRangeStart',
                value: dateRangeStart,
                onAccept: (date) => setDateRangeStart(date),
                onChange: (date) => setDateRangeStart(date),
                handleClear: () => setDateRangeStart(null),
                label: 'From',
                helperText: 'Required',
                disableFuture: true,
                placeholder: 'MM/DD/YYYY',
              }}
              ToProps={{
                name: 'dateRangeEnd',
                value: dateRangeEnd,
                onAccept: (date) => setDateRangeEnd(date),
                onChange: (date) => setDateRangeEnd(date),
                handleClear: () => setDateRangeEnd(null),
                label: 'To',
                helperText: '',
                disableFuture: true,
                placeholder: 'MM/DD/YYYY',
              }}
            />
          )}
          {variant === 'year' && (
            <DatePicker
              name="selectedYear"
              views={['year']}
              value={year}
              onAccept={(date) => setYear(date.format('YYYY'))}
              onChange={(date) => setYear(date.format('YYYY'))}
              handleClear={() => setYear(null)}
              label="Year"
              disableFuture
              placeholder="YYYY"
            />
          )}
        </Box>
        <InputLabel>
          <Box
            sx={{
              paddingBottom: 1,
            }}
          >
            Format
          </Box>
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            <Checkbox
              checked
              disabled
              label={
                <Box
                  sx={{
                    marginLeft: 1,
                  }}
                >
                  CSV
                </Box>
              }
            />
          </Box>
        </InputLabel>
      </Box>
    </>
  );
}
