import CashFlowTable from './table';
import React from 'react';

const headers = [
  { label: '', key: 'label', flex: 3, textAlign: 'left' },
  { label: 'Current Budget', key: 'currentBudget', flex: 1 },
  { label: 'Actual', key: 'actual', flex: 1 },
  {
    label: 'Proposed Budget',
    key: 'proposedBudget',
    flex: 1,
    editable: true,
    placeholder: '$ 0.00',
  },
  {
    label: 'Comments or (YTD)',
    key: 'comments',
    flex: 1,
    editable: true,
    placeholder: 'Comment or (YTD)',
  },
];

const data = [
  {
    header: 'Operational Cash Sources',
    rows: [
      {
        label: '1. Rental Income',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '2. RHS Rental Assistance Received',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '3. Application Fees Received',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '4. Laundry and Vending',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '5. Interest Income',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '6. Tenant Charges',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '7. Other - Project Sources',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '8. Less',
        labelSuffix: '(Vacancy and Contingency Allowance)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '9. Less',
        labelSuffix: '(Agency Approved Incentive Allowance)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '10. Sub-Total',
        labelSuffix: '[(1 thru 7) - (8 & 9)]',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
  {
    header: 'Non-Operational Cash Sources',
    rows: [
      {
        label: '11. Cash - Non Project',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '12. Authorized Loan',
        labelSuffix: '(Non-RHS)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '13. Transfer From Reserve',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '14. Sub-Total',
        labelSuffix: '(11 thru 13)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '15. Total Cash Sources',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
  {
    header: 'Operational Cash Uses',
    rows: [
      {
        label: '16. Total O&M Expenses',
        labelSuffix: '(From Part II)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '17. RHS Debt Payment',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '18. RHS Payment',
        labelSuffix: '(Overage)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '19. RHS Payment',
        labelSuffix: '(Late Fee)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '20. Reduction in Prior Year Payables',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '21. Tenant Utility Payments',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '22. Transfer to Reserve',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '23. Return to Owner / NP Asset Management Fee',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '24. Sub-Total',
        labelSuffix: '(16 thru 23)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
  {
    header: 'Non-Operational Cash Uses',
    rows: [
      {
        label: '25. Authorized Debt Payment',
        labelSuffix: '(Non-RHS)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '26. Annual Capital Budget',
        labelSuffix: '(From Part III, Lines 4-6)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '27. Miscellaneous',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '28. Sub-Total',
        labelSuffix: '(25 thru 27)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '29. Total Cash Uses',
        labelSuffix: '(24+28)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '30. Net Cash Deficit',
        labelSuffix: '(15-29)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
  {
    header: 'Cash Balance',
    rows: [
      {
        label: '31. Beginning Cash Balance',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '32. Accrual to Cash Adjustment',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
      {
        label: '33. Ending Cash Balance',
        labelSuffix: '(30+31+32)',
        currentBudget: '$0.00',
        actual: '$0.00',
        proposedBudget: '',
        comments: '',
      },
    ],
  },
];

const Page1 = ({ tableState, setTableState }) => (
  <CashFlowTable
    data={data}
    headers={headers}
    tableState={tableState}
    setTableState={setTableState}
  />
);

export default Page1;
