import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import useAbortController from '../../../hooks/useAbortController';
import FloorPlanService from '../../../services/floorPlanService';
import { processFloorPlanResult } from './utils';

export const useFloorPlansQuery = (
  organizationId: string,
  propertyId: string,
  isModalShown: boolean,
) => {
  const floorPlanService = useMemo(() => new FloorPlanService(), []);
  const abortControllerOptions = useAbortController();
  const queryKey = ['getAllWithPricingForRD', organizationId, propertyId];

  const { data, isLoading, refetch } = useQuery(
    queryKey,
    () =>
      floorPlanService.getAllWithPricingForRD(
        organizationId,
        propertyId,
        abortControllerOptions,
      ),
    {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      enabled: isModalShown,
    },
  );

  const floorPlans = data?.length ? processFloorPlanResult(data) : [];

  return {
    isLoading,
    floorPlans,
    refetch,
  };
};

export const useAsyncCreateFloorPlansRdLimits = ({
  organizationId,
  propertyId,
  options,
}) => {
  const floorPlanService = useMemo(() => new FloorPlanService(), []);
  const mutation = useMutation(
    ({ payload }) =>
      floorPlanService.createRdLimits(organizationId, propertyId, payload),
    options,
  );

  return {
    ...mutation,
  };
};
