import { useQuery, QueryOptions } from 'react-query';

import MincTransactionsService from '../../services/mincTransactionsService';

const useManageMincTransactionsQuery = ({
  organizationId,
  propertyId,
  startDate,
  endDate,
  options = {},
}: {
  organizationId: string,
  propertyId: string,
  householdId: string,
  options?: QueryOptions,
}) => {
  const manageMincTransactionsService = new MincTransactionsService();
  const queryKey = ['getAllMincTransactions', organizationId, propertyId];

  const { data, isLoading, refetch } = useQuery(
    queryKey,
    async () => {
      const response =
        await manageMincTransactionsService.getAllMincTransactions(
          organizationId,
          propertyId,
          startDate,
          endDate,
        );
      return response;
    },
    { refetchOnWindowFocus: false, ...options },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useManageMincTransactionsQuery;
