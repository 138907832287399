import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.RentForecast.RentForecastHeader.Title',
    defaultMessage: 'Forecast Rents',
  },
  createRentForecast: {
    id: 'App.RentForecast.RentForecastHeader.CreateRentForecast',
    defaultMessage: 'Create Rent Forecast',
  },
  newRentForecast: {
    id: 'App.RentForecast.NewRentForecast',
    defaultMessage: 'New Rent Forecast',
  },
  name: {
    id: 'App.RentForecast.Name',
    defaultMessage: 'Name',
  },
  placeholder: {
    id: 'App.RentForecast.Placeholder',
    defaultMessage: 'Forecast Name...',
  },
  floorPlans: {
    id: 'App.RentForecast.FloorPlans',
    defaultMessage: 'Floor Plans',
  },
  bedsBaths: {
    id: 'App.RentForecast.BedsBaths',
    defaultMessage: 'Beds/Baths',
  },
  numUnits: {
    id: 'App.RentForecast.NumUnits',
    defaultMessage: '# of Units',
  },
  currentBasicRent: {
    id: 'App.RentForecast.CurrentBasicRent',
    defaultMessage: 'Current Basic Rent',
  },
  currentNoteRent: {
    id: 'App.RentForecast.CurrentNoteRent',
    defaultMessage: 'Current Note Rent',
  },
  success: {
    id: 'App.RentForecast.Success',
    defaultMessage: 'Success',
  },
  createdForecast: {
    id: 'App.RentForecast.CreatedForecast',
    defaultMessage: 'Created Rent Forecast',
  },
  reviewRDBudget: {
    id: 'App.RentForecast.ReviewRDBudget',
    defaultMessage: 'Review RD Budget',
  },
});

export default messages;
