import { useMemo, useState } from 'react';
import useIntl from '../../hooks/useIntl';
import RentForecastService from '../../services/rentForecastService';
import { useMutation, useQuery } from 'react-query';
import messages from './messages';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import { FormattedMessage } from 'react-intl';
import {
  useTableFilterSortData,
  useTableFilterSortSearchManager,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { PROPERTY_PATH_MAP } from './constants';
import useRows from './hooks.useRows';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import { promptToaster } from '../App/actions';

const headers = [
  {
    id: 'floorPlan',
    label: <FormattedMessage {...messages.floorPlans} />,
    sortable: true,
  },
  {
    id: 'bedsBaths',
    label: <FormattedMessage {...messages.bedsBaths} />,
    sortable: true,
  },
  {
    id: 'numUnits',
    label: <FormattedMessage {...messages.numUnits} />,
    sortable: true,
  },
  {
    id: 'currentBasicRent',
    label: <FormattedMessage {...messages.currentBasicRent} />,
    sortable: true,
  },
  {
    id: 'currentNoteRent',
    label: <FormattedMessage {...messages.currentNoteRent} />,
    sortable: true,
  },
];

export const useManageRentForecast = ({ refreshFn }) => {
  const [results, setResults] = useState([]);
  const [forecastName, setForecastName] = useState('');
  const [currentRentForecast, setCurrentRentForecast] = useState('');
  const tableName = useUniqueTableName('rent-forecast');
  const { organizationId, id: propertyId } = useSelectedProperty();
  const handleResults = (data) => {
    const newData = data?.floorPlans?.map((item) => {
      return {
        floorPlan: item.internalName,
        floorPlanLink: `/property/${propertyId}/floorPlan/${item.id}`,
        bedsBaths: `${item.nBeds || 0} / ${item.nBaths || 0}`,
        numUnits: item?.floorplanUnitCount?.numTotalUnits || 0,
        currentBasicRent: item.basicRent,
        currentNoteRent: item.noteRent,
      };
    });
    setCurrentRentForecast(data?.rentForecasts?.[0]?.id);
    if (data?.rentForecasts?.[0]?.name) {
      setResults(newData);
    }
  };
  const callback = (data) => {
    handleResults(data);
    if (refreshFn) {
      refreshFn(data);
    }
  };

  const { results: rentForecasts, refetch: refetchRentForecasts } =
    useRentForecastQuery(organizationId, propertyId, handleResults);

  const { mutate: createRentForecast, isLoading } = useRentForecastMutation(
    organizationId,
    propertyId,
    forecastName,
    callback,
    promptToaster,
  );

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    tableName,
    headers,
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP: PROPERTY_PATH_MAP ?? {},
  });

  const rows = useRows({
    sortedAndFilteredResults,
  });

  const footerRow = {
    numUnits: {
      value: rows.reduce((acc, row) => acc + row.numUnits.value, 0),
      variant: 'number',
    },
    currentBasicRent: {
      value:
        rows.reduce((acc, row) => acc + row.currentBasicRent.value, 0) || null,
      variant: 'currency',
    },
    currentNoteRent: {
      value:
        rows.reduce((acc, row) => acc + row.currentNoteRent.value, 0) || null,
      variant: 'currency',
    },
  };

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    tableName,
    headers,
    firstRow: rows[0],
  });

  return {
    allColumnsHidden,
    columnOptions,
    count: rows.length ?? 0,
    createRentForecast,
    currentRentForecast,
    dateState,
    filterState,
    filterTypeState,
    footerRow,
    forecastName,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers: filteredHeaders,
    isLoading,
    order,
    orderBy,
    refetchRentForecasts,
    rentForecasts,
    rows,
    searchState,
    selectedColumns,
    setCurrentRentForecast,
    setForecastName,
    tableName,
    totalCount: rows.length ?? 0,
  };
};

export const useRentForecastMutation = (
  organizationId,
  propertyId,
  name,
  refreshFn: Function,
  toasterFn: Function,
  reactQueryOptions?: Object,
) => {
  const service = useMemo(() => new RentForecastService(), []);
  const intl = useIntl();

  return useMutation(
    async (values) => {
      const payload = JSON.stringify({
        name,
      });
      return service.createRentForecast(organizationId, propertyId, payload);
    },
    {
      ...reactQueryOptions,
      onSuccess: (data) => {
        toasterFn({
          title: intl.formatMessage(messages.success),
          message: intl.formatMessage(messages.createdForecast),
        });
        refreshFn(data);
      },
      onError: (error) => {
        toasterFn({
          type: 'error',
          message: error.toString(),
          title: 'Error',
        });
      },
    },
  );
};

export const useRentForecastQuery = (organizationId, propertyId, callback) => {
  const service = useMemo(() => new RentForecastService(), []);
  const queryKey = [
    'useManageUnitAvailabilityQuery',
    organizationId,
    propertyId,
  ];
  const { data, isLoading, refetch } = useQuery(
    queryKey,
    async () => {
      const rentForecasts = await service.getAllRentForecast(
        organizationId,
        propertyId,
      );
      callback(rentForecasts);
      return rentForecasts;
    },
    {
      enabled: Boolean(propertyId && organizationId),
    },
  );
  const results = useMemo(() => data ?? [], [data]);
  return {
    results,
    isLoading,
    refetch,
  };
};
