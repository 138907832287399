import React, { useState } from 'react';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Box } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import Page1 from './page1';
import Page2 from './page2';
import StatusCard from './statusCard';

const RDBudgetModal = (props) => {
  const { open, closeModal, forecastName } = props;
  const [tableData, setTableData] = useState({
    page: 0,
  });
  const [cardStatus] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
    4: false,
    5: true,
  });

  const statusCards = [
    {
      label: 'Cash Flow Stmt.',
      tooltip: 'Part I - Cash Flow Statement',
      part: 'I',
      component: <Page1 tableState={tableData} setTableState={setTableData} />,
    },
    {
      label: 'Op. & Maint.',
      tooltip: 'Part II - Operating & Maintenance',
      part: 'II',
      component: <Page2 tableState={tableData} setTableState={setTableData} />,
    },
    {
      label: 'Acct. Bdgt./Status',
      tooltip: 'Part III - Accounting Budget/Status',
      part: 'III',
    },
    {
      label: 'Annu. Cap. Bdgt.',
      tooltip: 'Part V - Annual Capital Budget',
      part: 'V',
    },
    { label: 'Review', tooltip: 'Review' },
    { label: 'Comments', tooltip: 'Comments' },
  ];

  const ActivePage = statusCards[tableData.page].component;

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={forecastName}
      actionsProps={[
        {
          children: 'Cancel',
          onClick: closeModal,
        },
        {
          children: 'Create 3560-7',
          submitButton: true,
          disabled: true,
        },
        {
          children: 'Save Budget',
          submitButton: true,
        },
      ]}
      ModalProps={{
        PaperProps: {
          sx: { width: '100%', maxWidth: 'lg' },
        },
      }}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            paddingBottom: '16px',
            overflowX: 'auto',
          }}
        >
          {statusCards.map((card, index) => (
            <StatusCard
              key={index}
              selected={tableData.page === index}
              onClick={() => setTableData((prev) => ({ ...prev, page: index }))}
              success={cardStatus[index]}
              {...card}
            />
          ))}
        </Box>
        {ActivePage && ActivePage}
      </>
    </Modal>
  );
};

export default RDBudgetModal;
