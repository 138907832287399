import {
  Switch,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { DatePicker } from '@fortress-technology-solutions/fortress-component-library/Organisms';

import { FormattedMessage } from 'react-intl';
import { TextInput } from '../../BasicDetails/TextInput';
import { displayAsDashesIfEmpty, stripOnlyWhiteSpaceToNull } from '../utils';
import { disableAllDaysOfTheMonthExceptFirst } from '../../../../utils/date-helpers';

import integrationSetupMessages from '../../IntegrationsSetup/messages';

import moment from 'moment';

export type PassbookRate = {
  id: string,
  startDate: string,
  endDate?: string,
  amount: number | string,
  programName: string,
  state: string,
};

export const SetupLabel = (props) => {
  return (
    <Typography variant={'inputLabel'} display={'block'} mb={1}>
      <FormattedMessage {...props} />
    </Typography>
  );
};

export const SetupValue = ({
  editMode,
  name,
  value,
  onChange,
  placeholder,
  editable = true,
  valueMapper,
  maxLength,
  required = false,
}) => {
  return (
    <Grid item xs={7} sx={{ padding: '0 5px' }}>
      {editMode && editable ? (
        <TextInput
          name={name}
          value={value}
          editMode={editMode}
          placeholder={placeholder}
          onChange={({ target: { name, value } }) => {
            onChange({
              target: {
                name,
                value: stripOnlyWhiteSpaceToNull(value),
              },
            });
          }}
          maxLength={maxLength ? maxLength : '255'}
          required={required}
        />
      ) : (
        <Typography variant={'body1'}>
          {valueMapper
            ? displayAsDashesIfEmpty(valueMapper[value])
            : displayAsDashesIfEmpty(value)}
        </Typography>
      )}
    </Grid>
  );
};

export const SetupSwitch = ({ editMode, name, value, onChange, switchId }) => {
  return (
    <Grid item xs={7} sx={{ padding: '0 5px' }}>
      {editMode ? (
        <Switch
          id={switchId}
          name={name}
          checked={value}
          disabled={!editMode}
          onChange={(evt) => {
            const name = evt.target.name;
            const value = evt.target.checked;
            onChange({ target: { name, value } });
          }}
        />
      ) : value ? (
        <Typography variant={'body1'} color={'primary.main'}>
          <FormattedMessage {...integrationSetupMessages.on} />
        </Typography>
      ) : (
        <Typography variant={'body1'} color={'text.secondary'}>
          <FormattedMessage {...integrationSetupMessages.off} />
        </Typography>
      )}
    </Grid>
  );
};

export const SetupTitle = ({ children }) => {
  return (
    <Grid item xs={12}>
      <Typography variant={'h4'}>{children}</Typography>
    </Grid>
  );
};

export const AnnualRecertMonthPicker = ({
  handleAnnualRecertMonthChange,
  editMode,
  programName,
  value,
}) => {
  const isNumberOfMonth = Number.isInteger(value) && value > 0;
  return (
    <DatePicker
      id={`${programName?.toLowerCase()}-annual-recert-month-picker`}
      views={['month']}
      format={'MMMM'}
      placeholder={'Select a month'}
      onChange={handleAnnualRecertMonthChange}
      disabled={!editMode}
      value={
        isNumberOfMonth
          ? moment().set('month', value - 1)
          : value
          ? moment(value)
          : null
      }
      shouldDisableDate={(date) => disableAllDaysOfTheMonthExceptFirst(date)}
      rules={{
        validate: (date) => {
          let isValid = false;
          if (date) {
            const dayOfMonth = new Date(date).getDate();
            isValid = dayOfMonth === 1;
          }
          if (!isValid) {
            return 'Date must be the first day of the month';
          }
          return isValid;
        },
      }}
    />
  );
};
