import { useMemo } from 'react';
import { FieldArray, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Typography,
  Button,
  IconButton,
  Stack,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  PlusIcon,
  TrashIcon,
  TimelapseIcon,
  MoneyIcon,
  AlertInfoIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import messages from './messages';
import {
  renderCLDatePickerField,
  renderCLTimePickerField,
  renderCLCurrencyField,
} from '../../../../utils/redux-form-helper';
import { formatCurrency } from '../../../../utils';

function TimeLog({ fields, array, intl, serviceLogs }) {
  const timeWidth = 135;

  if (!fields.length) return null;

  return (
    <>
      <div className="row" style={{ marginLeft: 0 }}>
        <div className="col-xs-12 col-md-3">
          <label>{intl.formatMessage(messages.serviceDate)}</label>
        </div>
        <div className="col-xs-12 col-md-3">
          <Stack direction="row" spacing={1}>
            <label
              style={{
                width: timeWidth,
              }}
            >
              {intl.formatMessage(messages.timeStart)}
            </label>
            <label
              style={{
                width: timeWidth,
              }}
            >
              {intl.formatMessage(messages.timeEnd)}
            </label>
          </Stack>
        </div>
      </div>
      {fields.map((serviceLog, index) => {
        const value = serviceLogs[index];
        const isServiceDateValid =
          value.serviceDate && moment(value.serviceDate).isValid();
        const isStartTimeValid =
          value.startTime && moment(value.startTime).isValid();
        return (
          <div
            key={serviceLog}
            className="row"
            style={{ marginLeft: 0, marginTop: index > 0 ? 6 : 0 }}
          >
            <div className="col-xs-12 col-md-3">
              <Field
                name={`${serviceLog}.serviceDate`}
                component={renderCLDatePickerField}
              />
            </div>
            <div className="col-xs-12 col-md-3">
              <Stack direction="row" spacing={1}>
                <Field
                  name={`${serviceLog}.startTime`}
                  component={renderCLTimePickerField}
                  width={timeWidth}
                  disabled={!isServiceDateValid}
                />
                <Field
                  name={`${serviceLog}.endTime`}
                  component={renderCLTimePickerField}
                  width={timeWidth}
                  minTime={isStartTimeValid ? value.startTime : null}
                  disabled={!isServiceDateValid}
                />
                <div>
                  <IconButton
                    onClick={() => {
                      array.remove('serviceLogs', index);
                    }}
                  >
                    <TrashIcon />
                  </IconButton>
                </div>
              </Stack>
            </div>
          </div>
        );
      })}
    </>
  );
}

function ServiceTimeAndCost({ intl, array, serviceLogs, hourlyServiceRate }) {
  const totalDuration = useMemo(() => {
    let totalMinutes = 0;

    serviceLogs.forEach(({ serviceDate, startTime, endTime }) => {
      if (
        serviceDate && // Check if serviceDate is valid
        moment(serviceDate).isValid() && // Check if serviceDate is valid
        startTime?.isValid() && // Check if startTime is valid
        endTime?.isValid() && // Check if endTime is valid
        startTime.isBefore(endTime) // Ensure startTime is before endTime
      ) {
        totalMinutes += moment.duration(endTime.diff(startTime)).asMinutes();
      }
    });

    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);

    return `${hours} hour${hours !== 1 ? 's' : ''}, ${minutes} minute${
      minutes !== 1 ? 's' : ''
    }`;
  }, [serviceLogs]);

  const totalHours = useMemo(() => {
    let totalMinutes = 0;

    serviceLogs.forEach(({ serviceDate, startTime, endTime }) => {
      if (
        serviceDate && // Check if serviceDate is provided
        moment(serviceDate).isValid() && // Validate serviceDate
        startTime?.isValid() && // Validate startTime
        endTime?.isValid() && // Validate endTime
        startTime.isBefore(endTime) // Ensure startTime is before endTime
      ) {
        totalMinutes += moment.duration(endTime.diff(startTime)).asMinutes();
      }
    });

    // Convert total minutes to fractional hours
    const totalHours = totalMinutes / 60;

    return totalHours;
  }, [serviceLogs]);

  return (
    <>
      <div className="col-xs-12 padtop10">
        <Typography variant="h3">
          {intl.formatMessage(messages.serviceTimeAndCost)}
        </Typography>
      </div>
      <div
        className={`col-xs-12 padtop20${
          serviceLogs.length > 0 ? ' padbottom20' : ''
        }`}
      >
        <label>Total Duration</label>
        <Stack direction="row" spacing={1}>
          <TimelapseIcon />
          <Typography variant="body1">{totalDuration}</Typography>
        </Stack>
      </div>
      <FieldArray
        name="serviceLogs"
        component={TimeLog}
        array={array}
        intl={intl}
        serviceLogs={serviceLogs}
      />
      <div className="col-xs-12 padtop20">
        <Button
          startIcon={<PlusIcon />}
          disabled={serviceLogs.length >= 10}
          onClick={() => {
            array.push('serviceLogs', {});
          }}
        >
          {intl.formatMessage(messages.addTimeLog)}
        </Button>
      </div>
      <div className="col-xs-12 col-md-3 padtop20">
        <label>{intl.formatMessage(messages.hourlyServiceRate)}</label>
        <Field
          name="hourlyServiceRate"
          marginTop={1}
          component={renderCLCurrencyField}
        />
      </div>
      <div className="col-xs-12 col-md-4 padtop20">
        <label style={{ display: 'flex', alignItems: 'center' }}>
          {intl.formatMessage(messages.totalLaborCost)}{' '}
          <Tooltip
            title={intl.formatMessage(messages.totalLaborCostTooltip)}
            arrow
            placement="top"
            variant="light"
          >
            <AlertInfoIcon
              fontSize="small"
              sx={{ marginLeft: 0.5, marginTop: 0.5 }}
            />
          </Tooltip>
        </label>
        <Stack direction="row" alignItems="center" sx={{ marginLeft: '-5px' }}>
          <MoneyIcon />
          <Typography>
            {formatCurrency(
              intl,
              hourlyServiceRate * totalHours,
              true,
            ).substring(1)}
          </Typography>
        </Stack>
      </div>
    </>
  );
}

const mapStateToProps = (
  { app, form }: Object,
  { initialValues }: Object,
): Object => {
  const selector = formValueSelector('workOrderForm');
  return {
    serviceLogs: selector({ form }, 'serviceLogs'),
    hourlyServiceRate: selector({ form }, 'hourlyServiceRate'),
  };
};

export default connect(mapStateToProps)(ServiceTimeAndCost);
