import { pathOr } from 'ramda';
import moment from 'moment';
import { formatDateTimeDisplayUTC } from '@fortress-technology-solutions/fortress-component-library/utils';
import { FEE_MODES } from '../../ViewUnit/EditFees/constants';
import messages from './messages';

export const processFloorPlanResult = (
  floorPlans: Array<Object>,
): Array<Object> => {
  let result = floorPlans;

  const rdFloorPlans = result.filter((floorPlan) =>
    floorPlan.floorPlanAffordablePrograms.some(
      (program) =>
        program.propertyAffordableProgram.masterAffordableProgram.name === 'RD',
    ),
  );

  return rdFloorPlans.map((floorPlan) => {
    const fpua = pathOr([], ['fpua'], floorPlan);
    const fpuaRD = fpua.filter((uaAllowance) => {
      return uaAllowance?.ua?.pha?.name === 'RD';
    });

    const lastfpuaRD = fpuaRD.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    )[0];

    const noteRents = pathOr([], ['noteRents'], floorPlan);
    const basicRents = pathOr([], ['basicRents'], floorPlan);
    const lastNoteRent = noteRents.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    )[0];

    const lastBasicRent = basicRents.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    )[0];

    const mapped = {
      ...floorPlan,
      noteRent: lastNoteRent?.feeAmount,
      disableDateNoteRent: lastNoteRent?.startDate,
      basicRent: lastBasicRent?.feeAmount,
      disableDateBasicRent: lastBasicRent?.startDate,
      rdUtilityAllowance: lastfpuaRD?.feeAmount,
      utilityAlowancesId: lastfpuaRD?.ua?.id,
      disableDateUtilityAllowance: lastfpuaRD?.startDate,
    };
    return mapped;
  });
};

export const shouldDisableDate = (date, disabledDate) => {
  if (date && disabledDate) {
    const selectedDate = moment(date).utc().startOf('day');
    const disabled = moment(disabledDate).utc().startOf('day');
    const today = moment().utc().startOf('day');
    return (
      selectedDate.isSameOrBefore(disabled, 'day') ||
      selectedDate.isBefore(today, 'day')
    );
  }

  return false;
};

export const mapperFloorPlans = (floorPlans = {}) => {
  const mappedFloorplans = Object.values(floorPlans)
    .map((floorplan) => {
      const {
        newBasicRent,
        newNoteRent,
        newRdUtilityAllowance,
        startDate,
        utilityAlowancesId,
      } = floorplan;

      const result = [];

      if (newBasicRent) {
        result.push({
          floorPlanId: floorplan.id,
          pricingType: FEE_MODES.BASIC_RENT,
          feeAmount: newBasicRent,
          implementationDate: moment
            .utc(startDate)
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          startDate: formatDateTimeDisplayUTC(startDate),
        });
      }

      if (newNoteRent) {
        result.push({
          floorPlanId: floorplan.id,
          pricingType: FEE_MODES.NOTE_RENT,
          feeAmount: newNoteRent,
          implementationDate: moment
            .utc(startDate)
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          startDate: formatDateTimeDisplayUTC(startDate),
        });
      }
      if (newRdUtilityAllowance) {
        result.push({
          floorPlanId: floorplan.id,
          pricingType: FEE_MODES.RD_UTILITY_ALLOWANCE,
          feeAmount: newRdUtilityAllowance,
          implementationDate: moment
            .utc(startDate)
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          startDate: formatDateTimeDisplayUTC(startDate),
          utilityAlowancesId,
        });
      }

      return result;
    })
    .flat();

  return mappedFloorplans;
};

const updateOnlyNoteRent = (data) => {
  return data.every((item) => item?.pricingType === FEE_MODES.NOTE_RENT);
};

const updateOnlyBasicRent = (data) => {
  return data.every((item) => item?.pricingType === FEE_MODES.BASIC_RENT);
};

const updateOnlyUtilityAllowance = (data) => {
  return data.every(
    (item) => item?.pricingType === FEE_MODES.RD_UTILITY_ALLOWANCE,
  );
};

export const getMessageSuccess = (data, intl) => {
  const rentOnly = updateOnlyNoteRent(data);
  if (rentOnly) {
    return intl.formatMessage(messages.noteRentSuccess);
  }
  const basicRentOnly = updateOnlyBasicRent(data);
  if (basicRentOnly) {
    return intl.formatMessage(messages.basicRentSuccess);
  }

  const uaOnly = updateOnlyUtilityAllowance(data);
  if (uaOnly) {
    return intl.formatMessage(messages.uaSuccess);
  }

  return intl.formatMessage(messages.allSuccess);
};
