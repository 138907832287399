import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageCertifications.Title',
    defaultMessage: 'Manage Certifications',
  },
  recerts: {
    id: 'App.ManageCertifications.Recerts',
    defaultMessage: 'Recerts',
  },
  grCerts: {
    id: 'App.ManageCertifications.GrossRentCerts',
    defaultMessage: 'Gross Rent',
  },
  rdGrCerts: {
    id: 'App.ManageCertifications.RdGrossRentCerts',
    defaultMessage: 'RD Gross Rent',
  },
  search: {
    id: 'App.ManageCertifications.Search',
    defaultMessage: 'Search',
  },
  selectOption: {
    id: 'App.ManageCertifications.SelectOption',
    defaultMessage:
      'Please click one of the certification options to get started',
  },
  programName: {
    id: 'App.ManageCertificationsContent.ProgramName',
    defaultMessage: 'Program Name',
  },
  unit: {
    id: 'App.ManageCertificationsContent.Unit',
    defaultMessage: 'Unit',
  },
  headOfHousehold: {
    id: 'App.ManageCertificationsContent.HeadOfHousehold',
    defaultMessage: 'Head of Household',
  },
  moveIn: {
    id: 'App.ManageCertificationsContent.MoveInDate',
    defaultMessage: 'Move-in Date',
  },
  leaseStartDate: {
    id: 'App.ManageCertificationsContent.LeaseStartDate',
    defaultMessage: 'Lease Start Date',
  },
  lastCertified: {
    id: 'App.ManageCertificationsContent.lastCertified',
    defaultMessage: 'Last Certified',
  },
  priorCertificationDate: {
    id: 'App.ManageCertificationsContent.PriorCertificationDate',
    defaultMessage: 'Prior Certification Date',
  },
  recertDue: {
    id: 'App.ManageCertificationsContent.RecertDue',
    defaultMessage: 'Recert Due',
  },
  doNotRenew: {
    id: 'App.ManageCertificationsContent.DoNotRenew',
    defaultMessage: 'Do Not Renew',
  },
  underEviction: {
    id: 'App.ManageCertificationsContent.UnderEviction',
    defaultMessage: 'Under Eviction',
  },
  balance: {
    id: 'App.ManageCertificationsContent.Balance',
    defaultMessage: 'Balance',
  },
  noticeToVacate: {
    id: 'App.ManageCertificationsContent.NoticeToVacate',
    defaultMessage: 'Occupied / On NTV',
  },
  recertStatus: {
    id: 'App.ManageCertificationsContent.RecertStatus',
    defaultMessage: 'Recert Status',
  },
  status: {
    id: 'App.ManageCertificationsContent.Status',
    defaultMessage: 'Status',
  },
  notice120: {
    id: 'App.ManageCertificationsContent.Notice120',
    defaultMessage: '120 Notice',
  },
  notice90: {
    id: 'App.ManageCertificationsContent.Notice90',
    defaultMessage: '90 Notice',
  },
  notice60: {
    id: 'App.ManageCertificationsContent.Notice60',
    defaultMessage: '60 Notice',
  },
  notice30: {
    id: 'App.ManageCertificationsContent.Notice30',
    defaultMessage: '30 Notice',
  },
  complianceDocument: {
    id: 'App.ManageCertificationsContent.ComplianceDocument',
    defaultMessage: 'Compliance Document',
  },
  certificationNotes: {
    id: 'App.ManageCertificationsContent.CertificationNotes',
    defaultMessage: 'Certification Notes',
  },
  floorPlan: {
    id: 'App.ManageCertificationsContent.FloorPlan',
    defaultMessage: 'Floor Plan',
  },
  newGrossRent: {
    id: 'App.ManageCertificationsContent.NewGrossRent',
    defaultMessage: 'New Gross Rent',
  },
  newHUDUA: {
    id: 'App.ManageCertificationsContent.NewHUDUA',
    defaultMessage: 'New HUD UA',
  },
  grEffectiveDate: {
    id: 'App.ManageCertificationsContent.GREffectiveDate',
    defaultMessage: 'GR Effective Date',
  },
  anticipatedVoucherDate: {
    id: 'App.ManageCertificationsContent.AnticipatedVoucherDate',
    defaultMessage: 'Anticipated Voucher Date',
  },
  otherCertInProgress: {
    id: 'App.ManageCertificationsContent.OtherCertInProgress',
    defaultMessage: 'Other Cert In Progress',
  },
  newSubsidy: {
    id: 'App.ManageCertificationsContent.NewSubsidy',
    defaultMessage: 'New Subsidy',
  },
  newResidentRent: {
    id: 'App.ManageCertificationsContent.NewResidentRent',
    defaultMessage: 'New Resident Rent',
  },
  newUtilityReimbursement: {
    id: 'App.ManageCertificationsContent.NewUtilityReimbursement',
    defaultMessage: 'New Utility Reimbursement',
  },
  residentSignatureRequired: {
    id: 'App.ManageCertificationsContent.ResidentSignatureRequired',
    defaultMessage: 'Resident Signature Required',
  },
  correction: {
    id: 'App.ManageCertificationsContent.Correction',
    defaultMessage: 'Correction',
  },
  uploadedDocument: {
    id: 'App.ManageCertificationsContent.UploadedDocument',
    defaultMessage: 'Uploaded Document',
  },
  generate59a: {
    id: 'App.ManageCertificationsContent.Generate59a',
    defaultMessage: 'Generate 59a',
  },
  action: {
    id: 'App.ManageCertificationsContent.Action',
    defaultMessage: 'Action',
  },
  generated59a: {
    id: 'App.ManageCertificationsContent.Generated59a',
    defaultMessage: '59a Generated',
  },
  moveIns: {
    id: 'App.ManageCertificationsContent.MoveIns',
    defaultMessage: 'Move-Ins',
  },
  moveInCertifications: {
    id: 'App.ManageCertificationsContent.MoveInCertifications',
    defaultMessage: 'Move-In Certifications',
  },
  certStatus: {
    id: 'App.ManageCertificationsContent.CertStatus',
    defaultMessage: 'Cert Status',
  },
  programType: {
    id: 'App.ManageCertificationsContent.ProgramType',
    defaultMessage: 'Program Type',
  },
  scheduledMoveInDate: {
    id: 'App.ManageCertificationsContent.ScheduledMoveInDate',
    defaultMessage: 'Scheduled / Actual MI Date',
  },
  currentResident: {
    id: 'App.ManageCertificationsContent.CurrentResident',
    defaultMessage: 'Current Resident',
  },
  daysSinceLastUpload: {
    id: 'App.ManageCertificationsContent.DaysSinceLastUpload',
    defaultMessage: 'Days Since Last Upload',
  },
  loadPropertyProgramsError: {
    id: 'App.ManageCertificationsContent.LoadPropertyProgramsError',
    defaultMessage:
      'There was an error loading the affordable programs for your property.',
  },
  loadComplianceApprovalStatusesError: {
    id: 'App.ManageCertificationsContent.LoadComplianceApprovalStatusesError',
    defaultMessage:
      'There was an error loading the compliance approval statuses.',
  },
  initial: {
    id: 'App.ManageCertificationsContent.Initial',
    defaultMessage: 'Initial',
  },
  initialCerts: {
    id: 'App.ManageCertificationsContent.InitialCerts',
    defaultMessage: 'Initial Certs',
  },
  initialCertifications: {
    id: 'App.ManageCertificationsContent.InitialCertifications',
    defaultMessage: 'Initial Certifications',
  },
  interimCertifications: {
    id: 'App.ManageCertificationsContent.InterimCertifications',
    defaultMessage: 'Interim Certifications',
  },
  effectiveDate: {
    id: 'App.ManageCertificationsContent.EffectiveDate',
    defaultMessage: 'Effective Date',
  },
  priorResident: {
    id: 'App.ManageCertificationsContent.PriorResident',
    defaultMessage: 'Prior Resident',
  },
  certType: {
    id: 'App.ManageCertificationsContent.CertType',
    defaultMessage: 'Cert Type',
  },
  lastCertifiedDate: {
    id: 'App.ManageCertificationsContent.LastCertified',
    defaultMessage: 'Last Certified',
  },
  nextRecertDueDate: {
    id: 'App.ManageCertificationsContent.NextRecertDueDate',
    defaultMessage: 'Next Recert Due Date',
  },
  other: {
    id: 'App.ManageCertificationsContent.Other',
    defaultMessage: 'Other',
  },
  otherHud: {
    id: 'App.ManageCertificationsContent.OtherHud',
    defaultMessage: 'Other HUD',
  },
  otherLihtc: {
    id: 'App.ManageCertificationsContent.OtherLihtc',
    defaultMessage: 'Other LIHTC',
  },
  interim: {
    id: 'App.ManageCertificationsContent.Interim',
    defaultMessage: 'Interim',
  },
  moveOut: {
    id: 'App.ManageCertificationsContent.MoveOut',
    defaultMessage: 'Move-Out',
  },
  termination: {
    id: 'App.ManageCertificationsContent.Termination',
    defaultMessage: 'Termination',
  },
  moveInCert: {
    id: 'App.ManageCertificationsContent.MoveInCert',
    defaultMessage: 'Move-In',
  },
  unitTransfer: {
    id: 'App.ManageCertificationsContent.UnitTransfer',
    defaultMessage: 'Unit Transfer',
  },
  initialCert: {
    id: 'App.ManageCertificationsContent.InitialCert',
    defaultMessage: 'Initial',
  },
  initialUt: {
    id: 'App.ManageCertificationsContent.InitialUt',
    defaultMessage: 'Initial (Transfer)',
  },
  interimUt: {
    id: 'App.ManageCertificationsContent.InterimUt',
    defaultMessage: 'Interim (Transfer)',
  },
  recertUt: {
    id: 'App.ManageCertificationsContent.RecertUt',
    defaultMessage: 'Recert (Transfer)',
  },
  certifications: {
    id: 'App.ManageCertificationsContent.Certifications',
    defaultMessage: 'Certifications',
  },
  noChangeInGrossRentCerts: {
    id: 'App.ManageCertifications.NoChangeInGrossRentCerts',
    defaultMessage:
      'If the assistance details for the Gross Rent certification do not have a change from the prior certification, ' +
      'the column will list NC for No Change.',
  },
  manualCorrectionInGrossRentCerts: {
    id: 'App.ManageCertifications.ManualCorrectionInGrossRentCerts',
    defaultMessage:
      'The correction information for the Gross Rent certification will need to be manually ' +
      'entered by selecting the “Edit Certification” action before you can download the 59a.',
  },
  priorCertNotCompleted: {
    id: 'App.ManageCertifications.PriorCertNotCompleted',
    defaultMessage:
      'A prior certification has not been completed in Fortress. The prior certification ' +
      'information will need to be manually entered by selecting the “Edit Certification” ' +
      'action before you can download the 59a.',
  },
  lateRecerts: {
    id: 'App.ManageCertifications.LateRecerts',
    defaultMessage: 'Late Recerts',
  },
  under30: {
    id: 'App.ManageCertifications.Under30',
    defaultMessage: '30 Days and Under',
  },
  between31And60: {
    id: 'App.ManageCertifications.Between31And60',
    defaultMessage: '31-60 Days',
  },
  between61And90: {
    id: 'App.ManageCertifications.Between61And90',
    defaultMessage: '61-90 Days',
  },
  between90And120: {
    id: 'App.ManageCertifications.Between90And120',
    defaultMessage: '91-120 Days',
  },
  late: {
    id: 'App.ManageCertifications.Late',
    defaultMessage: 'Late',
  },
  upcoming: {
    id: 'App.ManageCertifications.Upcoming',
    defaultMessage: 'Upcoming',
  },
  generateAll59AsSuccess: {
    id: 'App.ManageCertifications.GenerateAll59AsSuccess',
    defaultMessage: 'Generated All 59As Successfully',
  },
  generatingAll59As: {
    id: 'App.ManageCertifications.GeneratingAll59As',
    defaultMessage: 'Generating All 59As',
  },
  willDownloadWhenComplete: {
    id: 'App.ManageCertifications.WillDownloadWhenComplete',
    defaultMessage: 'Will download when complete',
  },
  errorGeneratingAll59As: {
    id: 'App.ManageCertifications.ErrorGeneratingAll59As',
    defaultMessage:
      'There was an error generating the 59As batch {errorMessage}',
  },
  generateAll59As: {
    id: 'App.ManageCertifications.GenerateAll59As',
    defaultMessage: 'Generate All 59As',
  },
  generateAllRdGrCerts: {
    id: 'App.ManageCertifications.generateAllRdGrCerts',
    defaultMessage: 'Generate All RD GR Certs',
  },
  signed59AMissing: {
    id: 'App.ManageCertifications.Signed59AMissing',
    defaultMessage: 'Signed 59A Missing',
  },
  newNoteRent: {
    id: 'App.ManageCertificationsContent.newNoteRent',
    defaultMessage: 'New Note Rent',
  },
  newBasicRent: {
    id: 'App.ManageCertificationsContent.newBasicRent',
    defaultMessage: 'New Basic Rent',
  },
  newRDUa: {
    id: 'App.ManageCertificationsContent.newRDUa',
    defaultMessage: 'New RD UA',
  },
  generateRDGrossRent: {
    id: 'App.ManageCertificationsContent.generateRDGrossRent',
    defaultMessage: 'Generate RD Gross Rent Cert',
  },
});

export default messages;
