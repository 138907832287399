import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import ProgramPassbookRate from '../ProgramPassbookRate';
import SetupGridItem from '../../../SetupGridItem';
import { SetupSwitch, AnnualRecertMonthPicker } from '../shared';

import type { PassbookRate } from '../shared';

import { useHOME } from './hooks';
import messages from './messages';
import messagesAffordableSetup from '../../messages';

interface HomeFlags {
  passbookRate: boolean;
  rolloverIncomeAndAssets: boolean;
}

export interface HomeValues {
  HOMEAffordabilityPeriodStartDate: string;
  HOMEAnnualIncomeType: string;
  HOMEPropertyType: string;
  HOMEUnitType: string;
  autoRolloverIncomeAndAssets: boolean;
  organizationId: string;
  propertyId: string;
  flags: HomeFlags;
  passbookRates?: Array<PassbookRate>;
}

const HomeSetup = ({
  editMode,
  isLoadingPassbookRateData,
  onChange,
  promptToaster,
  values: { flags, passbookRates, ...values },
}) => {
  const { ReactHookFormProps, memoizedFields } = useHOME({
    editMode,
    onChange,
    values,
  });

  const handleHOMEAnnualRecertMonthChange = (
    date: Object,
    validate: Object,
  ) => {
    onChange({
      target: { name: 'setup.HOMEAnnualRecertMonth', value: date },
    });
  };

  return (
    <>
      <Form
        ReactHookFormProps={ReactHookFormProps}
        header={
          <Typography variant={'h4'}>
            <FormattedMessage {...messages.title} />
          </Typography>
        }
        fields={memoizedFields}
      >
        <Grid container spacing={2}>
          {flags.rolloverIncomeAndAssets && (
            <>
              <Grid item xs={12} sm={6} />
              <SetupGridItem
                label={
                  <FormattedMessage
                    {...messagesAffordableSetup.autoRolloverIncomeAndAssets}
                  />
                }
                TooltipProps={{
                  title: (
                    <FormattedMessage
                      {...messagesAffordableSetup.autoRolloverIncomeAndAssetsDescription}
                    />
                  ),
                }}
              >
                <SetupSwitch
                  switchId={'home-auto-rollover-income-and-assets-switch'}
                  editMode={editMode}
                  name="setup.HOMEAutoRolloverIncomeAndAssets"
                  disabled={!editMode}
                  value={values.autoRolloverIncomeAndAssets}
                  onChange={onChange}
                />
              </SetupGridItem>
            </>
          )}
        </Grid>
        <SetupGridItem
          label={
            <FormattedMessage
              {...messagesAffordableSetup.annualRecertMonth}
              values={{ program: 'HOME' }}
            />
          }
        >
          <AnnualRecertMonthPicker
            handleAnnualRecertMonthChange={handleHOMEAnnualRecertMonthChange}
            editMode={editMode}
            programName={'HOME'}
            value={values.HOMEAnnualRecertMonth}
          />
        </SetupGridItem>
        {flags.passbookRate && (
          <ProgramPassbookRate
            key={'HOME_PassbookRates'}
            programName={'HOME'}
            isLoadingPassbookRateData={isLoadingPassbookRateData}
            passbookRates={passbookRates}
            sx={{ paddingTop: 1, paddingRight: 0, paddingLeft: 0 }}
          />
        )}
      </Form>
    </>
  );
};

export default HomeSetup;
