import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import ManageCertificationsTabs from './ManageCertificationsTabs';
import CertificationsSummary from './CertificationsSummary';
import ManageCertificationModals from './ManageCertificationModals';
import CertificationNotes from './CertificationNotes';
import generalMessages from '../App/messages';
import componentMessages from './messages';
import GenerateAll59AsButton from './GenerateAll59AsButton';
import GenerateAllRDGRCerts from './GenerateAllRDGRCerts';
import {
  GROSS_RENT_CERT_TYPE,
  RD_GROSS_RENT_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';
import useBaseTableProps from '../../hooks/useBaseTableProps';
import TableLayout from '../../components/TableLayout';
import ManageCertificationsLegend from './ManageCertificationsLegend';

// Hooks
import { useManageCertifications } from './hooksV2';
import { useFlags } from 'launchdarkly-react-client-sdk';

const messages = { ...generalMessages, ...componentMessages };

const NAME = 'manage-certifications';

export const ManageCertifications = ({ intl }) => {
  const baseTableProps = useBaseTableProps();
  const {
    affordableSetup,
    allColumnsHidden,
    columnOptions,
    count,
    countName,
    dateState,
    disableDownloadAll59AsButton,
    editGrossRentCertificationMutation,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    hasSelectedCertificationType,
    isLoading,
    modalProps,
    name,
    onCSVButtonClick,
    order,
    orderBy,
    propertyAffordablePrograms,
    refetchCertifications,
    rows,
    searchState,
    selectedCertificationType,
    selectedColumns,
    setSelectedCertificationType,
    signed59ADocumentType,
    sortedAndFilteredResults,
    summary,
    totalCount,
    uploadSigned59AMutation,
  } = useManageCertifications({ NAME });

  const { grossRentEnhancements: grossRentEnhancementsFlag } = useFlags();

  const actionMap = {
    [GROSS_RENT_CERT_TYPE]: (
      <GenerateAll59AsButton
        disabled={disableDownloadAll59AsButton}
        intl={intl}
      />
    ),
    [RD_GROSS_RENT_CERT_TYPE]: (
      <GenerateAllRDGRCerts
        disabled={disableDownloadAll59AsButton}
        intl={intl}
      />
    ),
  };

  return (
    <DocumentTitle
      data-testid={'manage-certifications'}
      title={'Manage Certifications'}
    >
      <>
        <TableLayout
          name={name}
          childrenElementsHeight={113 + 158}
          watch={['certificationsSummary']}
        >
          {(tableHeight) => (
            <>
              <TableHeader
                title={'Manage Certifications'}
                id={'tableHeader'}
                actions={
                  selectedCertificationType
                    ? actionMap[selectedCertificationType]
                    : undefined
                }
                Tabs={
                  <ManageCertificationsTabs
                    propertyAffordablePrograms={propertyAffordablePrograms}
                    selectedCertificationType={selectedCertificationType}
                    setSelectedCertificationType={setSelectedCertificationType}
                    affordableSetup={affordableSetup}
                  />
                }
                count={hasSelectedCertificationType ? count : undefined}
                countName={countName}
                totalCount={totalCount}
              />
              {grossRentEnhancementsFlag && (
                <ManageCertificationsLegend
                  certificationType={selectedCertificationType}
                />
              )}
              <CertificationsSummary
                propertyAffordablePrograms={propertyAffordablePrograms}
                summary={summary}
              />
              <CertificationNotes
                sortedAndFilteredResults={sortedAndFilteredResults}
                selectedCertificationType={selectedCertificationType}
              />
              <Table
                {...baseTableProps}
                allColumnsHidden={allColumnsHidden}
                columnOptions={columnOptions}
                dateState={dateState}
                emptyStateMessage={
                  !hasSelectedCertificationType ? (
                    <FormattedMessage {...messages.selectOption} />
                  ) : undefined
                }
                filterState={filterState}
                filterTypeState={filterTypeState}
                handleColumnChange={handleColumnChange}
                handleDateSubmit={handleDateSubmit}
                handleFilterChange={handleFilterChange}
                handleFilterTypeChange={handleFilterTypeChange}
                handleSearchSubmit={handleSearchSubmit}
                handleSortChange={handleSortChange}
                headers={filteredHeaders}
                height={tableHeight}
                isLoading={isLoading}
                name={name}
                onCSVButtonClick={
                  hasSelectedCertificationType && count > 0
                    ? onCSVButtonClick
                    : undefined
                }
                order={order}
                orderBy={orderBy}
                rows={rows}
                count={count}
                searchState={searchState}
                selectedColumns={selectedColumns}
                totalCount={totalCount}
              />
            </>
          )}
        </TableLayout>
        <ManageCertificationModals
          {...modalProps}
          signed59ADocumentType={signed59ADocumentType}
          uploadSigned59AMutation={uploadSigned59AMutation}
          editGrossRentCertificationMutation={
            editGrossRentCertificationMutation
          }
          refetchCertifications={refetchCertifications}
          propertyAffordablePrograms={propertyAffordablePrograms}
        />
      </>
    </DocumentTitle>
  );
};

export default injectIntl(ManageCertifications);
