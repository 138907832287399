import { defineMessages } from 'react-intl';

/**
 * Will have the messages displayed in ManageMincTransactions component
 */
const messages = defineMessages({
  title: {
    id: 'App.ManageMincTransactions.Title',
    defaultMessage: 'Manage MINC Transactions',
  },
  generateReport: {
    id: 'App.ManageMincTransactions.GenerateReport',
    defaultMessage: 'Generate Report',
  },
  chooseDates: {
    id: 'App.ManageMincTransactions.ChooseDates',
    defaultMessage: 'Choose Dates',
  },
  // Table Headers
  headOfHousehold: {
    id: 'App.ManageMincTransactions.HeadOfHousehold',
    defaultMessage: 'Head of Household',
  },
  unitNumber: {
    id: 'App.ManageMincTransactions.UnitNumber',
    defaultMessage: 'Unit',
  },
  certificationType: {
    id: 'App.ManageMincTransactions.CertificationType',
    defaultMessage: 'Cert Type',
  },
  effectiveDate: {
    id: 'App.ManageMincTransactions.EffectiveDate',
    defaultMessage: 'Effective Date',
  },
  certificationCompletionDate: {
    id: 'App.ManageMincTransactions.CertificationCompletionDate',
    defaultMessage: 'Cert Completion Date',
  },
  status: {
    id: 'App.ManageMincTransactions.Status',
    defaultMessage: 'Status',
  },
  // Messages to use later on for modals
  modalTitle: {
    id: 'App.ManageMincTransactions.modalTitle',
    defaultMessage: 'Modal Title',
  },
  testField: {
    id: 'App.ManageMincTransactions.TestField',
    defaultMessage: 'Test Field',
  },
});

export default messages;
