import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';

import FloorPlanService from '../../services/floorPlanService';
import { promptToaster } from '../App/actions';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import {
  calculateRentWithAmenities,
  calculateBaseMarketRent,
  getIsAffordableMixedProperty,
  getIsProperty236,
} from '../../utils/lease-helpers';
import {
  getFloorPlanType,
  getAffordablePlanDetails,
  processFloorPlanDataToCSV,
  parse236Rents,
} from './utils';
import { formatCurrency } from '../../utils';
import {
  useTableFilterSortSearchManager,
  useTableFilterSortData,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils/index';
import { HEADERS, TOTAL_COLUMNS, PROPERTY_PATH_MAP } from './table-constants';
import { useSmartPrices, useSmartPricesHistory } from './hooks-smart-pricing';
import { appendFilterTextToCSV } from '../../utils/csv-helpers';
import { download } from '../../utils/downloadFile';
import { HUD_SUBSIDY_CODES } from '../AffordableQualificationTab/Shared/utils';
import SmartPriceStatus from './SmartPriceStatus';
import useUniqueTableName from '../../hooks/useUniqueTableName';

export const useManageFloorplans = ({
  NAME,
  intl,
  selectedProperty,
  smartPricingEnabled,
  openUnitsByFloorplanModal,
  openVacantUnitsModal,
  openMissingSmartPricesModal,
  flags,
}) => {
  const name = useUniqueTableName(NAME);
  const { section236Flag } = flags;
  const propertyClass = selectedProperty?.propertyClass?.propertyType;

  const isAffordable = propertyClass === 'Affordable';
  const isCommercialMixed = propertyClass === 'Commercial-Mixed';
  const isMixed = propertyClass === 'Mixed' || isCommercialMixed;
  const isAffordableMixedProperty = getIsAffordableMixedProperty({
    property: selectedProperty,
  });
  const { noteRent, basicRent } = selectedProperty?.setup?.rdProjectType
    ?.rdrents || { noteRent: false, basicRent: false };

  const isPropertySection236 = getIsProperty236({
    property: selectedProperty,
    HUD_SUBSIDY_CODES,
  });
  const { floorPlans, isLoading, refetch } = useFetchManageFloorplans({
    selectedProperty,
    isAffordable,
    isMixed,
  });

  const [{ smartPrices, lastUpdatedAt }] = useSmartPrices(
    selectedProperty?.organizationId,
    selectedProperty?.id,
    smartPricingEnabled,
  );
  const [history, refetchSmartPricesHistory] = useSmartPricesHistory(
    selectedProperty?.organizationId,
    selectedProperty?.id || null,
    smartPricingEnabled || false,
  );

  const { hasHUD, hasLIHTC, hasRD } = getAffordablePlanDetails(
    floorPlans || [],
  );
  const { results } = useParseResults({
    floorPlans,
    selectedProperty,
    smartPrices,
    isPropertySection236,
    showRDNoteRent: noteRent,
    showRDBasicRent: basicRent,
  });

  const showSection236 =
    section236Flag &&
    (results?.some((fp) => fp.isSection236) || isPropertySection236);

  const filterOptions = useGetFilterOptions({ results });
  const headers = useHeaders({
    isAffordableMixedProperty,
    hasHUD,
    hasLIHTC,
    filterOptions,
    openMissingSmartPricesModal,
    smartPricingEnabled,
    allSmartPricesLoaded: smartPrices.length === floorPlans.length,
    showSection236,
    showRDNoteRent: noteRent,
    showRDBasicRent: basicRent,
  });

  const {
    filterState,
    filterTypeState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    searchState,
    dateState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
  });
  const propertyPathMap = useMemo(
    () =>
      PROPERTY_PATH_MAP({
        showSection236,
        showRDNoteRent: noteRent,
        showRDBasicRent: basicRent,
      }),
    [showSection236, noteRent, basicRent],
  );

  const sortedAndFilteredResults = useTableFilterSortData({
    results,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    PROPERTY_PATH_MAP: propertyPathMap,
  });
  const footerRow = useGetFooterRow({
    sortedAndFilteredResults,
    filteredHeaders: headers,
  });

  const rows = useRows({
    intl,
    floorPlans: sortedAndFilteredResults,
    selectedProperty,
    isAffordable,
    isMixed,
    hasHUD,
    hasLIHTC,
    openUnitsByFloorplanModal,
    openVacantUnitsModal,
    smartPrices,
    history,
    smartPricingEnabled,
    showSection236,
    showRDNoteRent: noteRent,
    showRDBasicRent: basicRent,
  });

  const {
    allColumnsHidden,
    columnOptions,
    selectedColumns,
    handleColumnChange,
    filteredHeaders,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const { onCSVButtonClick } = useCSVPDFExport({
    propertyName: selectedProperty?.name,
    hasAnyFilters:
      Object.keys(filterState)?.length ||
      Object.keys(dateState)?.length ||
      Object.keys(searchState)?.length,
    filteredHeaders,
    rows,
    results: sortedAndFilteredResults,
  });

  return {
    allColumnsHidden,
    count: rows?.length ?? 0,
    totalCount: results?.length ?? 0,
    floorPlans: rows,
    filteredHeaders,
    smartPriceLastUpdate: lastUpdatedAt,
    columnOptions,
    footerRow,
    isLoading,
    hasHUD,
    hasRD,
    isAffordableMixedProperty,
    filterState,
    filterTypeState,
    name,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleColumnChange,
    refetchSmartPricesHistory,
    refetchFloorPlans: refetch,
    selectedColumns,
    searchState,
    onCSVButtonClick,
  };
};

const useFetchManageFloorplans = ({ selectedProperty = {} }) => {
  const { id, organizationId } = selectedProperty;
  const abortController = new AbortController();
  const queryKey = [id, organizationId];
  const options = { signal: abortController.signal };
  const { data, isLoading, isSuccess, refetch } = useQuery(
    ['manageFloorplans', queryKey],
    () => new FloorPlanService().getAll(organizationId, id, options),
    {
      enabled: Boolean(id.length && organizationId.length),
      refetchOnWindowFocus: false,
      onError: (e) => {
        promptToaster({
          type: 'error',
          title: 'Error loading floor plans',
          message: e.toString(),
        });
      },
    },
  );

  const floorPlans = useMemo(() => data ?? [], [data]);
  return {
    floorPlans,
    isLoading,
    isSuccess,
    refetch,
    count: floorPlans.length,
  };
};

const useParseResults = ({
  floorPlans,
  selectedProperty,
  smartPrices,
  isPropertySection236,
  showRDBasicRent,
  showRDNoteRent,
}) => {
  return useMemo(() => {
    const results = floorPlans.map((floorPlan) => {
      const { isHUD } = getFloorPlanType(floorPlan);

      const { baseMarketRentAmount, grossRentLimit } = calculateBaseMarketRent({
        floorplan: floorPlan,
        property: selectedProperty,
      });
      const quotingRentAmount = calculateRentWithAmenities(
        floorPlan,
        floorPlan.quotingRentAmount,
      );
      const isCommercial =
        floorPlan.isCommercial || floorPlan.isCommercialLeasingActive;

      const isSection236 =
        (floorPlan?.floorPlanAffordablePrograms ?? [])
          .map((fpap) => fpap?.hudCode)
          .includes(HUD_SUBSIDY_CODES.SECTION_236) || isPropertySection236;

      const { hud236MarketRent, hud236BasicRent } = isSection236
        ? parse236Rents({
            floorPlan,
          })
        : { hud236MarketRent: null, hud236BasicRent: null };
      return {
        floorPlanId: floorPlan.id,
        floorPlanMarketingName: floorPlan.marketingName,
        floorPlanName: floorPlan.internalName,
        bedsBaths: `${floorPlan.nBeds}/${floorPlan.nBaths}`,
        sqFt: floorPlan.grossSquareFeet,
        numUnits: floorPlan.floorplanUnitCount?.numTotalUnits || 0,
        baseFloorPlanMarketRent: baseMarketRentAmount || 0,
        financialMarketRent: floorPlan.financialMarketRentAmount || 0,
        quotingRent: quotingRentAmount || 0,
        grossRentLimit: grossRentLimit || 0,
        hudGrossRentLimit: isHUD ? floorPlan.HUDGrossRent : 0 || 0,
        suggestedDeposit: floorPlan.estimatedDepositAmount,
        smartPrice: !isCommercial
          ? smartPrices[floorPlan.internalName]?.recommendedPrice.toFixed(0) ||
            0
          : 0,
        isCommercial,
        isSection236,
        hud236BasicRent: hud236BasicRent,
        hud236MarketRent: hud236MarketRent,
        noteRent: showRDNoteRent ? floorPlan.noteRent : null,
        basicRent: showRDBasicRent ? floorPlan.basicRent : null,
      };
    });
    return { results };
  }, [
    floorPlans,
    isPropertySection236,
    selectedProperty,
    smartPrices,
    showRDBasicRent,
    showRDNoteRent,
  ]);
};

const useHeaders = ({
  isAffordableMixedProperty,
  hasHUD,
  hasLIHTC,
  openMissingSmartPricesModal,
  filterOptions,
  smartPricingEnabled,
  showSection236,
  showRDBasicRent,
  showRDNoteRent,
}) => {
  return useMemo(() => {
    const baseHeaders = HEADERS({
      isAffordableMixedProperty,
      hasHUD,
      hasLIHTC,
      smartPricingEnabled,
      openMissingSmartPricesModal,
      showSection236,
      showRDBasicRent,
      showRDNoteRent,
    });
    let headers = [];
    baseHeaders.forEach((header) => {
      if (filterOptions[`${header.id}Options`])
        header.filterOptions = filterOptions[`${header.id}Options`];
      headers.push(header);
    });
    return headers;
  }, [
    showSection236,
    showRDBasicRent,
    showRDNoteRent,
    isAffordableMixedProperty,
    hasHUD,
    hasLIHTC,
    filterOptions,
    smartPricingEnabled,
    openMissingSmartPricesModal,
  ]);
};
const useGetFooterRow = ({ sortedAndFilteredResults, filteredHeaders }) => {
  return useMemo(() => {
    const totalsColumns = JSON.parse(JSON.stringify(TOTAL_COLUMNS));
    sortedAndFilteredResults.forEach((result) => {
      Object.keys(totalsColumns).forEach((id) => {
        if (result[id]) {
          const addition =
            typeof result[id] === 'string'
              ? parseFloat(result[id])
              : result[id];
          if (id === 'numUnits') {
            totalsColumns[id].value += addition;
          } else {
            // Add the amount for each unit
            totalsColumns[id].value += addition * (result.numUnits || 0);
          }
        }
      });
    });

    const results = filteredHeaders.reduce((object, { id }) => {
      object[id] = totalsColumns[id] ?? '';
      return object;
    }, {});

    return results;
  }, [sortedAndFilteredResults, filteredHeaders]);
};
const useRows = ({
  intl,
  floorPlans = [],
  isAffordable,
  isMixed,
  openUnitsByFloorplanModal,
  openVacantUnitsModal,
  history,
  smartPricingEnabled,
  showSection236,
  showRDBasicRent,
  showRDNoteRent,
}) => {
  return useMemo(() => {
    return floorPlans?.map(
      ({
        floorPlanId,
        floorPlanName,
        floorPlanMarketingName,
        bedsBaths,
        sqFt,
        numUnits,
        baseFloorPlanMarketRent,
        financialMarketRent,
        quotingRent,
        grossRentLimit,
        hudGrossRentLimit,
        suggestedDeposit,
        smartPrice,
        hud236BasicRent,
        hud236MarketRent,
        noteRent,
        basicRent,
        isCommercial,
        ...rest
      }) => {
        const smartPriceLog = history[floorPlanId];
        const recommendedPrice = smartPrice;
        const smartPriceStatus =
          smartPriceLog?.lastLog &&
          smartPriceLog.lastLog.newAmount === +recommendedPrice
            ? smartPriceLog.lastLog.action === 'ACCEPT'
              ? 'Accepted'
              : 'Rejected'
            : quotingRent === +recommendedPrice
            ? 'No Change'
            : 'Waiting Approval';
        const smartPriceUpdatedBy =
          (smartPriceStatus === 'Accepted' ||
            smartPriceStatus === 'Rejected') &&
          smartPriceLog?.lastLog
            ? {
                name:
                  smartPriceLog.lastLog.createdBy.firstName +
                  ' ' +
                  smartPriceLog.lastLog.createdBy.lastName,
                date: moment(smartPriceLog.lastLog.createdAt).format(
                  'MM/DD/YYYY',
                ),
              }
            : undefined;

        return {
          floorPlanName: {
            variant: 'link',
            to: getUrlWithSelectedPropertyId(`/floorPlan/${floorPlanId}`),
            value: floorPlanName,
          },
          floorPlanMarketingName: {
            value: floorPlanMarketingName,
          },
          bedsBaths: {
            value: bedsBaths,
          },
          sqFt: {
            variant: 'number',
            value: sqFt,
          },
          numUnits: {
            variant: 'link',
            value: numUnits || '0',
            onClick:
              numUnits > 0
                ? () =>
                    openUnitsByFloorplanModal({
                      floorPlanId,
                      floorInternalName: floorPlanName,
                      numUnits,
                    })
                : undefined,
          },
          ...(showRDNoteRent && {
            noteRent: {
              variant: 'currency',
              value: noteRent,
            },
          }),
          ...(showRDBasicRent && {
            basicRent: {
              variant: 'currency',
              value: basicRent,
            },
          }),
          baseFloorPlanMarketRent: {
            variant: 'currency',
            value: baseFloorPlanMarketRent,
          },
          financialMarketRent: {
            variant: 'currency',
            value: financialMarketRent,
          },
          quotingRent: {
            variant: 'currency',
            value: quotingRent,
          },
          ...(showSection236
            ? {
                hud236BasicRent: {
                  variant: 'currency',
                  value: hud236BasicRent,
                },
                hud236MarketRent: {
                  variant: 'currency',
                  value: hud236MarketRent,
                },
              }
            : {}),
          grossRentLimit: {
            variant: 'currency',
            value: grossRentLimit,
          },
          ...((isAffordable || isMixed) && {
            hudGrossRentLimit: {
              variant: 'currency',
              value: hudGrossRentLimit,
            },
          }),
          suggestedDeposit: {
            variant: 'currency',
            value: suggestedDeposit,
          },
          ...(smartPricingEnabled && {
            smartPrice: {
              variant: 'link',
              iconName: recommendedPrice ? 'LaunchExternalIcon' : undefined,
              value: formatCurrency(intl, recommendedPrice || null),
              disabled: !recommendedPrice || isCommercial,
              onClick: () =>
                recommendedPrice
                  ? openVacantUnitsModal({
                      floorPlanId,
                      floorInternalName: floorPlanName,
                      floorPlanMarketingName,
                      selectedSmartPrice: recommendedPrice || null,
                      quotingRent,
                      smartPriceLastLog: history[floorPlanId]?.lastLog || null,
                      smartPriceStatus,
                      numUnits,
                    })
                  : undefined,
            },
            smartPriceStatus: {
              value: recommendedPrice ? (
                <SmartPriceStatus
                  status={smartPriceStatus}
                  updatedBy={smartPriceUpdatedBy}
                />
              ) : null,
              sx: {
                display: 'flex',
              },
            },
          }),
        };
      },
    );
  }, [
    intl,
    isAffordable,
    isMixed,
    floorPlans,
    openUnitsByFloorplanModal,
    openVacantUnitsModal,
    history,
    smartPricingEnabled,
    showSection236,
    showRDBasicRent,
    showRDNoteRent,
  ]);
};

const useGetFilterOptions = ({ results }) => {
  return useMemo(() => {
    const options = {
      bedsBathsOptions: [],
      floorPlanNameOptions: [],
    };

    if (results) {
      results.forEach(({ floorPlanName, bedsBaths }) => {
        if (floorPlanName?.length)
          options.floorPlanNameOptions.push(floorPlanName);
        if (bedsBaths?.length) options.bedsBathsOptions.push(bedsBaths);
      });

      options.floorPlanNameOptions = Array.from(
        new Set(options.floorPlanNameOptions),
      )
        .sort()
        .map((floorPlan) => ({
          text: floorPlan,
          value: floorPlan?.toLowerCase(),
        }));

      options.bedsBathsOptions = Array.from(new Set(options.bedsBathsOptions))
        .sort()
        .map((bedsBaths) => ({
          text: bedsBaths,
          value: bedsBaths,
        }));
    }

    return options;
  }, [results]);
};

const useCSVPDFExport = ({
  filteredHeaders,
  rows,
  propertyName,
  hasAnyFilters,
}) => {
  const onCSVButtonClick = useCallback(() => {
    const csvHeaders = filteredHeaders
      .map(({ label }) =>
        typeof label === 'string' ? label : label.props?.defaultMessage,
      )
      .join(',');
    const csvRows = processFloorPlanDataToCSV({ rows, filteredHeaders });

    const csv = appendFilterTextToCSV({
      headers: csvHeaders,
      rows: csvRows,
      hasAnyFilters,
    });

    download(
      csv,
      `FloorPlan_${propertyName?.split(' ').join('-')}_${formatDateDB(
        new Date(),
      )}.csv`,
      'text/csv;charset=utf-8',
    );
  }, [filteredHeaders, rows, hasAnyFilters, propertyName]);

  return { onCSVButtonClick };
};
