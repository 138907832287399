import { useMemo } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import LeaseService from '../../services/leaseService';

const leaseService = new LeaseService();

export const useFetchSavedRentSteps = ({ leaseId, lease }) => {
  const initialRentStep = useMemo(
    () => [
      {
        idx: 0,
        id: 0,
        leaseId: lease?.id,
        startDate: lease?.startDate,
        endDate: lease?.endDate,
        amount: lease?.leasedRent,
      },
    ],
    [lease],
  );
  const { data, isLoading } = useQuery(
    'rentSteps',
    () => leaseService.getLeaseRentSteps(leaseId),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      initialData: [],
    },
  );

  return {
    data: data.length > 0 ? data : initialRentStep,
    isLoading,
  };
};

export const useFetchRentStepByDate = ({ leaseId, date }) => {
  const { data, isLoading } = useQuery(
    'rentSteps',
    () => leaseService.getLeaseRentSteps(leaseId),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      initialData: [],
    },
  );
  const stepByDate = data.find(
    (step) =>
      moment(date).isAfter(moment(step.startDate)) &&
      (!step.endDate || moment(date).isBefore(moment(step.endDate))),
  );

  return {
    stepByDate,
    isLoading,
  };
};
