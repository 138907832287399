import { useCallback } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import {
  Typography,
  Stack,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { MoneyIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Field } from 'redux-form';
import messages from './messages';
import { renderCLCurrencyField } from '../../../../utils/redux-form-helper';
import { formatCurrency } from '../../../../utils';

function MaterialsUsed({
  intl,
  totalCostOfMaterialsUsed,
  totalAmountOwedByResident,
}) {
  const isTotalCostOfMaterialsUsedEntered =
    totalCostOfMaterialsUsed &&
    totalCostOfMaterialsUsed !== '.' &&
    totalCostOfMaterialsUsed > 0;

  const getTotalAmountOwedByProperty = useCallback(() => {
    const diff = totalCostOfMaterialsUsed - totalAmountOwedByResident;
    if (!isTotalCostOfMaterialsUsedEntered || diff < 0) return '0.00';
    return formatCurrency(intl, diff, true).substring(1);
  }, [
    totalAmountOwedByResident,
    totalCostOfMaterialsUsed,
    intl,
    isTotalCostOfMaterialsUsedEntered,
  ]);

  return (
    <>
      <div className="col-xs-12 padtop10">
        <Typography variant="h3">
          {intl.formatMessage(messages.materialsUsed)}
        </Typography>
      </div>
      <div className="col-xs-12 col-md-3 padtop20">
        <label>{intl.formatMessage(messages.totalCostOfMaterialsUsed)}</label>
        <Field
          name="totalCostOfMaterialsUsed"
          marginTop={1}
          component={renderCLCurrencyField}
        />
      </div>
      <div className="col-xs-12 col-md-3 padtop20">
        <label>{intl.formatMessage(messages.totalAmountOwedByResident)}</label>
        <Field
          name="totalAmountOwedByResident"
          marginTop={1}
          disabled={!isTotalCostOfMaterialsUsedEntered}
          component={renderCLCurrencyField}
        />
      </div>
      <div className="col-xs-12 col-md-4 padtop20">
        <label>{intl.formatMessage(messages.totalAmountOwedByProperty)}</label>
        <Stack direction="row" alignItems="center" sx={{ marginLeft: '-5px' }}>
          <MoneyIcon />
          <Typography>{getTotalAmountOwedByProperty()}</Typography>
        </Stack>
      </div>
    </>
  );
}

const mapStateToProps = (
  { app, form }: Object,
  { initialValues }: Object,
): Object => {
  const selector = formValueSelector('workOrderForm');
  return {
    totalCostOfMaterialsUsed: selector({ form }, 'totalCostOfMaterialsUsed'),
    totalAmountOwedByResident: selector({ form }, 'totalAmountOwedByResident'),
  };
};

export default connect(mapStateToProps)(MaterialsUsed);
