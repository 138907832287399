import React from 'react';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Table } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useFetchUnitsByFloorPlan } from './hooks';
import componentMessages from './messages';
import generalMessages from '../../App/messages';
import {
  Button,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

const messages = { ...generalMessages, ...componentMessages };

const UnitsByFloorPlanModal = ({
  show,
  onClose,
  propertyId,
  organizationId,
  floorPlanId,
  floorInternalName,
  numUnits,
  intl,
}: Object) => {
  const { data, isLoading } = useFetchUnitsByFloorPlan({
    organizationId,
    propertyId,
    floorPlanId,
    show,
  });
  return (
    <Modal
      open={show}
      onClose={onClose}
      title={`${intl.formatMessage(
        messages.unitsAssignedTo,
      )} ${floorInternalName} ${intl.formatMessage(messages.floorPlan)}`}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            {intl.formatMessage(messages.close)}
          </Button>
        </>
      }
      ModalProps={{
        PaperProps: {
          sx: {
            minWidth: {
              xs: 'calc(100vw - 8px)',
              sm: 600,
              md: 800,
            },
          },
        },
      }}
    >
      <Typography
        variant="bodySmall"
        component={'div'}
        sx={{ marginBottom: 2 }}
      >
        {intl.formatMessage(messages.noOfUnits)}
        <Typography
          variant="bodySmall"
          color={'primary'}
          sx={{ paddingLeft: 0.5 }}
        >
          {numUnits}
        </Typography>
      </Typography>

      <Table
        headers={[
          {
            id: 'number',
            label: intl.formatMessage(messages.unitNumber),
            sortable: false,
          },
          {
            id: 'unitFloorLevel',
            label: intl.formatMessage(messages.unitLevel),
            sortable: false,
          },
          {
            id: 'isADA',
            label: intl.formatMessage(messages.unitAda),
            sortable: false,
          },
          {
            id: 'description',
            label: intl.formatMessage(messages.unitStatus),
            sortable: false,
          },
          {
            id: 'resident',
            label: intl.formatMessage(messages.resident),
            sortable: false,
          },
          {
            id: 'applicant',
            label: intl.formatMessage(messages.applicant),
            sortable: false,
          },
        ]}
        rows={data}
        emptyStateMessage={intl.formatMessage(messages.emptyStateMessage)}
        count={data?.length || 0}
        totalCount={data?.length || 0}
        isLoading={isLoading}
        showSettings={false}
        sx={{ padding: '0px' }}
      />
    </Modal>
  );
};

export default UnitsByFloorPlanModal;
