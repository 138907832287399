import { post, get, put, del } from '../utils/api';

type HousingAssistanceVoucher = {
  voucherSource: string,
  voucherAmount: number,
  voucherStartDate: Date,
  voucherEndDate: Date,
};

/**
 * Will manage CRUD operations for different Housing Assistance types including vouchers
 */
export default class HousingAssistanceVoucherService {
  createVoucher(
    organizationId: string,
    propertyId: string,
    householdId: string,
    payload: HousingAssistanceVoucher,
    options?: Object,
  ): any {
    return post(
      `/${organizationId}/${propertyId}/${householdId}/housing-assistance-vouchers`,
      JSON.stringify(payload),
      options,
    );
  }

  getVoucherById(
    organizationId: string,
    propertyId: string,
    housingAssistanceVoucherId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/housing-assistance-vouchers/${housingAssistanceVoucherId}`,
      options,
    );
  }

  getAllVouchersByHouseholdId(
    organizationId: string,
    propertyId: string,
    householdId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/${householdId}/housing-assistance-vouchers`,
      options,
    );
  }

  editVoucher(
    organizationId: string,
    propertyId: string,
    householdId: string,
    housingAssistanceVoucherId: string,
    payload: HousingAssistanceVoucher,
    options?: Object,
  ): any {
    return put(
      `/${organizationId}/${propertyId}/${householdId}/housing-assistance-vouchers/${housingAssistanceVoucherId}`,
      JSON.stringify(payload),
      options,
    );
  }

  deleteVoucher(
    organizationId: string,
    propertyId: string,
    housingAssistanceVoucherId: string,
    options?: Object,
  ): any {
    return del(
      `/${organizationId}/${propertyId}/housing-assistance-vouchers/${housingAssistanceVoucherId}`,
      options,
    );
  }

  // Bussiness logic
  getCurrentActiveVoucher(
    organizationId: string,
    propertyId: string,
    householdId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/${householdId}/housing-assistance-vouchers/current-active`,
      options,
    );
  }
}
