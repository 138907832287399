import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormControl, FormGroup, InputGroup, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

// Components
import CreatableSelect from 'react-select/creatable';
import {
  Card,
  SubmitButton,
  Switch,
  Tooltip as FortressTooltip,
  Typography,
  Stack,
  Divider,
  Box,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { TextInput } from '../BasicDetails/TextInput';
import confirm from '../../../components/ConfirmDialogModal';
import ManageLeaseExpirationLimitsLink from '../../../components/ManageLeaseExpirationLimitsLink';
import { LeaseEndDateSetupSection } from './LeaseEndDateSettings';
import SetupGridItem from '../SetupGridItem';
import OnOffTypography from '../OnOffTypography';
import SetLeaseEndDate from './SetLeaseEndDate';
import FortressPaymentsConfig from './FortressPaymentsConfigData';

// Utils
import capitalize from 'lodash/capitalize';
import isNaN from 'lodash/isNaN';
import { ascend, prop, sortWith, uniqBy, isNil } from 'ramda';
import Numbers2Words from 'numbers2words';
import { removeNonNumericCharacters } from '../../../utils';
import {
  formatQuoteExpirationTime,
  getQuoteExpirationOptions,
  getSuccessiveLeaseTermOptions,
  formatHousingAssistanceVoucher,
  getHousingAssistanceVoucherOptions,
} from './utils';
import { getBooleanDisplayValue, getN2WLocale } from '../utils';
import { getUsesFortressPayments } from './utils';

// Constants
import componentMessages from './messages';
import generalMessages from '../../App/messages';
import { SUCCESSIVE_LEASE_TERM_MAP } from './constants';
import { AFFORDABLE_PROGRAMS } from '../../../utils/affordable';

// Hooks
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUpdateLeaseExpirationLimit } from './hooks';

import { AppContext } from '../../App/context';

// Types
import type { ProrateMethod } from '../types';
import type { SetLeaseEndDateProps } from './SetLeaseEndDate/types';

const messages = { ...componentMessages, ...generalMessages };

const List = styled.li`
  list-style-type: none;
  margin: 2px;
`;
const Span = styled.span`
  background-color: #d1d1d1;
`;

type PropertyLateMethod = {
  id: string,
  masterLateMethod: {
    lateMethodDescription: string,
  },
};

type PropertyDetails = {
  id: string,
  isDomusoActive: boolean,
  isPayLeaseActive: boolean,
  leaseRentPercentage: number,
  sitePetWeightMax: string,
  cashPrepaidGLCode: string,
  turnMoveOutDays: string,
  propertyLateMethods: PropertyLateMethod[],
  hasCommercialFloorPlans: boolean,
  applicationIncomeMultiplier: string,
  renewalProrateMethodId: string,
  moveOutProrateMethodId: string,
  isSetLeaseEndOfMonthFlagEnabled: boolean,
  quoteExpTime: string,
};

type LeaseTermOption = {
  label: string,
  value: number,
};

type MasterLeaseTerm = {
  createdAt: string,
  description: string,
  id: string,
  nMonths: number,
  organizationId: string,
  readableId: number,
  updatedAt: string,
};

export type Props = {
  propertyDetails: PropertyDetails,
  editMode: boolean,
  onChange: Function,
  leaseTerms: LeaseTermOption[],
  setLeaseTerms: Function,
  propertyLeaseTermsRef: { current: LeaseTermOption[] },
  masterLeaseTerms: MasterLeaseTerm[],
  disabled: boolean,
  prorateMethods: Array<ProrateMethod>,
  firstOfMonthPostEnabled: boolean,
  mltPosting: boolean,
  mltPostingError: string | null,
  postMLT: Function,
  isLeaseExpirationLimitsActive: boolean,
  organizationId: string,
  toasterFn: Function,
  intl: Object,
  propertyDetailsIsLoading: boolean,
  locale: string,
  moveOutProrateMethods: Array<ProrateMethod>,
};

const selectStyles = {
  container: (s) => ({ ...s, maxWidth: '90%' }),
};

const StyledSelect = styled.select`
  max-width: ${(props) => props.maxWidth};
  padding: 4px 2px;
  display: inline;
  margin: 0 0.375%;
`;

export const getPropsForSetLeaseEndDateSection = ({
  editMode,
  onChange,
  isSetLeaseEndDateToEndOfMonth,
  propertyDetailsIsLoading,
}: Partial<Props>): SetLeaseEndDateProps => {
  return {
    isEditMode: editMode,
    onFormChangeHandler: onChange,
    isSetLeaseEndDateToEndOfMonth,
    propertyDetailsIsLoading,
  };
};

const LeaseSetup = (props: Props) => {
  const {
    propertyDetails,
    editMode: editModeProp,
    onChange,
    leaseTerms,
    setLeaseTerms,
    propertyLeaseTermsRef,
    masterLeaseTerms,
    disabled,
    prorateMethods,
    firstOfMonthPostEnabled,
    mltPosting,
    mltPostingError,
    postMLT,
    organizationId,
    toasterFn,
    history,
    intl,
    propertyDetailsIsLoading,
    locale,
    isAutoChargeMtmFees,
    moveOutProrateMethods,
  } = props;
  const {
    id,
    isDomusoActive,
    isPayLeaseActive,
    leaseRentPercentage,
    sitePetWeightMax,
    cashPrepaidGLCode,
    turnMoveOutDays,
    propertyLateMethods,
    hasCommercialFloorPlans,
    applicationIncomeMultiplier,
    renewalProrateMethodId,
    moveOutProrateMethodId,
    deprecated_moveOutProrateMethodId,
    isSetLeaseEndDateToEndOfMonth,
    quoteExpTime,
    isNoEndDateActive,
    config: {
      adverseActionDeadlineDays,
      autoPostMonthlyCharges,
      roundProratedRents = false,
      successiveLeaseTerm,
      housingAssistanceVouchers,
      addUtilityNameToUtilityReimbursementChecks,
    },
    paymentProviders,
    pap,
  } = propertyDetails;

  const n2wLocale = getN2WLocale(locale);
  const n2w = new Numbers2Words(n2wLocale);

  const editMode = editModeProp && !disabled;

  const usesFortressPayments = getUsesFortressPayments(paymentProviders);

  const {
    leaseExpirationMgmtV1,
    moveOutProratedRentAdminSetting,
    quoteExpirationDate,
    leaseEndEnhance,
    transactionEndDate,
    adminEnhancementsPropertyinfoAutopostcharges,
    adverseActionLetter = false,
    settingLeaseEndLastDayOfMonth: isSetLeaseEndOfMonthFlagEnabled,
    autoChargeMtmFees,
    fortressPaymentsPropertyConfiguration,
    prorateRentsForMoveOuts,
    housingChoiceVouchers: housingAssistanceVouchersFlag,
    utilityCompanyNameOnReimbursementChecks:
      utilityCompanyNameOnReimbursementChecksFlag,
  } = useFlags();
  const appContext = React.useContext(AppContext);

  const currentMoveOutProrateMethodId = prorateRentsForMoveOuts
    ? moveOutProrateMethodId
    : deprecated_moveOutProrateMethodId;

  const [leaseTermsInput, setLeaseTermsInput] = useState('');
  const [postedMLT, setPostedMLT] = useState(false);
  const [
    isLeaseExpirationLimitsActive,
    isUpdateLeaseExpirationLimitLoading,
    updateLeaseExpirationLimit,
  ] = useUpdateLeaseExpirationLimit(
    props.isLeaseExpirationLimitsActive,
    organizationId,
    id,
    toasterFn,
  );
  const isAutoChargeMtmFeesOn = useMemo(
    () => [true, null].includes(isAutoChargeMtmFees),
    [isAutoChargeMtmFees],
  );
  const showLeaseExpirationLimits = useMemo(() => {
    const isCommercialProperty = hasCommercialFloorPlans === 'ALL';

    return (
      !propertyDetailsIsLoading &&
      leaseExpirationMgmtV1 &&
      !isCommercialProperty
    );
  }, [
    leaseExpirationMgmtV1,
    hasCommercialFloorPlans,
    propertyDetailsIsLoading,
  ]);
  const showAutoChargeMtmFeesSetting = autoChargeMtmFees === true;

  const handleLeaseExpirationLimitsToggle = (_e: Object, value: boolean) => {
    if (value === true) {
      confirm('Lease Expiration Limits Activation', {
        body: (
          <>
            Turning Lease Expiration Limits on for this property will activate
            the "Lease Expiration Limits" button on Manage Expiring Leases Tab.
            This will also{' '}
            <strong>set all required years to a 20% limit per month.</strong>{' '}
            Please ensure these are edited as necessary.
          </>
        ),
        confirmText: 'Save Changes',
        cancelText: 'Cancel',
      }).then(() => {
        updateLeaseExpirationLimit(true);
      });
    } else {
      updateLeaseExpirationLimit(false);
    }
  };

  const handleToNoEndDateMonthlyTransactions = (_e: Object, value: boolean) => {
    onChange({
      target: { name: 'isNoEndDateActive', value },
    });
  };

  const handleAutoPostMonthlyCharges = (event: Object, value: boolean) => {
    onChange({
      target: { name: 'config.autoPostMonthlyCharges', value },
    });
  };

  const handleAutoChargeMtmFeesChange = (event: Object, value: boolean) => {
    onChange({
      target: { name: 'config.autoChargeMtmFees', value },
    });
  };

  const handleRoundProratedRentsChange = (evt: Object, value: boolean) => {
    onChange({
      target: { name: 'config.roundProratedRents', value },
    });
  };

  const infoTipMessage = (
    <p style={{ fontSize: '12px', textAlign: 'center' }}>
      <FormattedMessage {...messages.isNoEndDateActiveTip} />
    </p>
  );

  const adverseLetterDaysTooltipMessage = (
    <p style={{ fontSize: '12px', textAlign: 'center' }}>
      <FormattedMessage {...messages.adverseLetterDaysTooltipMessage} />
    </p>
  );

  const lateMethods = propertyLateMethods.map((propertyLateMethods) => (
    <List key={propertyLateMethods.id}>
      <Span>{propertyLateMethods.masterLateMethod.lateMethodDescription}</Span>
    </List>
  ));

  const leaseTermsOptions = uniqBy(
    (lt) => lt.label,
    [
      ...propertyLeaseTermsRef.current,
      ...masterLeaseTerms.map((lt) => ({
        value: lt.nMonths,
        label: lt.nMonths,
      })),
    ],
  );

  // Sorting leaseTerms in ascending order before display
  const leaseTermSort = sortWith([ascend(prop('label'))]);

  const handleLeaseTermsChange = (newValues: any[] = []) => {
    if (!newValues) {
      setLeaseTerms([]);
      return;
    }

    const cleanedNewValues = newValues.map((v) => {
      const isNew =
        v.isNew ||
        !propertyLeaseTermsRef.current.find((lt) => lt.value === v.value);

      return isNew
        ? {
            value: +v.value,
            label: +v.label,
            isNew: true,
          }
        : v;
    });

    setLeaseTerms(uniqBy((lt) => lt.label, cleanedNewValues));
  };

  const getProrateOptions = (prorateType: string) =>
    prorateMethods.map((opt) => (
      <option key={`${prorateType}-prorate-${opt.id}`} value={opt.id}>
        {opt.name}
      </option>
    ));

  const getMoveOutProrateOptions = () => {
    return moveOutProrateMethods.map((opt) => (
      <option key={`${opt.id}`} value={opt.id}>
        {opt.name}
      </option>
    ));
  };

  const getCurrentProrateMethod = (prorateMethodId: string) => {
    const method = prorateMethods.find((m) => m.id === prorateMethodId);
    return method?.name ?? '---';
  };
  const getCurrentMoveOutProrateMethod = (prorateMethodId: string) => {
    const method = moveOutProrateMethods.find((m) => m.id === prorateMethodId);
    return method?.name ?? '---';
  };

  const firstOfMonthTooltip = (
    <Tooltip key={'tooltip-mlt'} id={'tooltip-mlt'}>
      {mltPostingError}
    </Tooltip>
  );

  const getLeaseExpirationLimit = () => {
    if (isLeaseExpirationLimitsActive === undefined) return messages.tripleDash;

    return isLeaseExpirationLimitsActive ? messages.on : messages.off;
  };

  const getAutoChargeMtmFeesTextValue = () => {
    if (isAutoChargeMtmFees === undefined) return messages.tripleDash;

    return isAutoChargeMtmFeesOn ? messages.on : messages.off;
  };

  const getIsNoEndDateActive = () => {
    if (isNoEndDateActive === undefined) return '---';

    return isNoEndDateActive ? 'On' : 'Off';
  };

  const adverseActionDeadlineDaysValidationMessage = useMemo(() => {
    if (isNil(adverseActionDeadlineDays)) return undefined;
    if (adverseActionDeadlineDays % 1 !== 0)
      return messages.valueMustBeWholeNumber;
    if (adverseActionDeadlineDays <= 0) return messages.valueGreaterThan0;
    if (adverseActionDeadlineDays > 999) return messages.valueLessThan999;
    return undefined;
  }, [adverseActionDeadlineDays]);

  const adverseActionDeadlineDaysValue = parseInt(
    adverseActionDeadlineDays ?? 10,
  );
  const adverseActionDeadlineDaysText =
    !editMode && !isNaN(adverseActionDeadlineDaysValue)
      ? capitalize(n2w.toWords(adverseActionDeadlineDaysValue))
      : '---';

  const handleHousingAssistanceVoucherChange = (event: Object) => {
    const value = event.target.value ?? false;
    onChange({
      target: { name: 'config.housingAssistanceVouchers', value },
    });
  };

  const doesPropertyHaveHudOrRd = useMemo(() => {
    if (!pap) return false;
    const propertyProgramNames = pap.map(
      (pap) => pap.masterAffordableProgram?.name,
    );
    return (
      propertyProgramNames.includes(AFFORDABLE_PROGRAMS.RD) ||
      propertyProgramNames.includes(AFFORDABLE_PROGRAMS.HUD)
    );
  }, [pap]);

  return (
    <Card
      sx={{ padding: 2, height: '100%' }}
      aria-label="Lease and Property Setup"
    >
      <Typography variant={'h3'}>
        <FormattedMessage {...messages.LeaseSetup} />
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      <Grid container spacing={2}>
        <PaymentProviderField
          isDomusoActive={isDomusoActive}
          isPayLeaseActive={isPayLeaseActive}
          usesFortressPayments={usesFortressPayments}
        />

        <SetupGridItem label={<FormattedMessage {...messages.leaseVariance} />}>
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon>%</InputGroup.Addon>
              <FormControl
                name="leaseRentPercentage"
                value={removeNonNumericCharacters(leaseRentPercentage)}
                disabled={!editMode}
                onChange={({ target: { name, value } }) => {
                  onChange({
                    target: {
                      name,
                      value: removeNonNumericCharacters(value).substr(0, 2),
                    },
                  });
                }}
                placeholder="Lease Variance"
              />
            </InputGroup>
          </FormGroup>
        </SetupGridItem>

        <SetupGridItem
          label={<FormattedMessage {...messages.petWeightLimit} />}
        >
          {editMode ? (
            <TextInput
              name="sitePetWeightMax"
              value={sitePetWeightMax}
              editMode={editMode}
              onChange={onChange}
              placeholder="(lbs)"
            />
          ) : (
            sitePetWeightMax || '---'
          )}
        </SetupGridItem>

        <SetupGridItem label={<FormattedMessage {...messages.lateFeeMethod} />}>
          <Typography>{lateMethods}</Typography>
        </SetupGridItem>

        {hasCommercialFloorPlans !== 'ALL' && (
          <>
            <SetupGridItem
              label={<FormattedMessage {...messages.allowedLeaseTerms} />}
            >
              <CreatableSelect
                styles={selectStyles}
                classNamePrefix="react-multi-select"
                value={leaseTermSort(leaseTerms)}
                isMulti
                isDisabled={!editMode}
                onChange={handleLeaseTermsChange}
                inputValue={leaseTermsInput}
                onInputChange={(v) => {
                  setLeaseTermsInput(removeNonNumericCharacters(v));
                }}
                options={leaseTermSort(leaseTermsOptions)}
              />
            </SetupGridItem>

            <SetupGridItem
              label={
                <>
                  <FormattedMessage
                    {...messages.applicationIncomeRequirement}
                  />
                  *
                </>
              }
            >
              {editMode ? (
                <TextInput
                  name="applicationIncomeMultiplier"
                  value={applicationIncomeMultiplier}
                  editMode={editMode}
                  onChange={onChange}
                  required
                />
              ) : (
                applicationIncomeMultiplier || '---'
              )}
            </SetupGridItem>

            {isSetLeaseEndOfMonthFlagEnabled && !leaseEndEnhance && (
              <SetLeaseEndDate
                {...getPropsForSetLeaseEndDateSection({
                  editMode,
                  onChange,
                  isSetLeaseEndDateToEndOfMonth,
                  propertyDetailsIsLoading,
                })}
              />
            )}
            {leaseEndEnhance && (
              <LeaseEndDateSetupSection
                intl={intl}
                editMode={editMode}
                onChange={onChange}
                propertyDetails={propertyDetails}
                propertyDetailsIsLoading={propertyDetailsIsLoading}
              />
            )}
          </>
        )}
        {transactionEndDate && (
          <SetupGridItem
            label={
              <FormattedMessage
                {...messages.defaultToNoEndDateMonthlyTransactions}
              />
            }
            TooltipProps={{
              title: infoTipMessage,
              children: <AlertInfoIcon />,
            }}
          >
            {editMode ? (
              <Switch
                checked={isNoEndDateActive}
                onChange={handleToNoEndDateMonthlyTransactions}
              />
            ) : (
              <OnOffTypography on={isNoEndDateActive}>
                {getIsNoEndDateActive()}
              </OnOffTypography>
            )}
          </SetupGridItem>
        )}
        {adminEnhancementsPropertyinfoAutopostcharges && (
          <SetupGridItem
            aria-label={`${intl.formatMessage(
              messages.autoPostMonthlyCharges,
            )} ${intl.formatMessage(messages.setting)}`}
            label={<FormattedMessage {...messages.autoPostMonthlyCharges} />}
          >
            {editMode ? (
              <Switch
                name="config.autoPostMonthlyCharges"
                inputProps={{
                  'aria-label': `${intl.formatMessage(
                    messages.autoPostMonthlyCharges,
                  )} ${intl.formatMessage(messages.setting)}`,
                }}
                checked={autoPostMonthlyCharges}
                onChange={handleAutoPostMonthlyCharges}
              />
            ) : (
              <OnOffTypography on={autoPostMonthlyCharges}>
                {getBooleanDisplayValue(autoPostMonthlyCharges)}
              </OnOffTypography>
            )}
            {editMode && (
              <Typography variant="caption" component="p">
                <FormattedMessage
                  {...messages.noteCustom}
                  values={{
                    note: intl.formatMessage(
                      messages.autoPostMonthlyChargesNote,
                    ),
                  }}
                />
              </Typography>
            )}
          </SetupGridItem>
        )}

        {(adminEnhancementsPropertyinfoAutopostcharges ? true : editMode) && (
          <SetupGridItem>
            <FortressTooltip
              key={'tooltip-overlay'}
              title={firstOfMonthTooltip}
              arrow
              placement="top"
              variant="light"
            >
              <SubmitButton
                id="post-first-of-month"
                variant={'shout'}
                isSubmitting={mltPosting}
                disabled={
                  !firstOfMonthPostEnabled ||
                  mltPosting ||
                  postedMLT ||
                  (adminEnhancementsPropertyinfoAutopostcharges && !editMode)
                }
                onClick={() => {
                  confirm(
                    <FormattedMessage
                      {...messages.confirmFirstOfMonthPostLabel}
                    />,
                  ).then(() => {
                    postMLT();
                    setPostedMLT(true); // disable button regardless.
                  });
                }}
              >
                <FormattedMessage {...messages.firstOfMonthPostLabel} />
              </SubmitButton>
            </FortressTooltip>
          </SetupGridItem>
        )}

        <SetupGridItem
          label={
            <>
              <FormattedMessage {...messages.turnDays} />*
            </>
          }
        >
          {editMode ? (
            <TextInput
              name="turnMoveOutDays"
              value={turnMoveOutDays}
              editMode={editMode}
              onChange={onChange}
              required={true}
              type="number"
              validateFunction={(value) =>
                value !== '' && Number.parseInt(value) > 0
              }
              message={
                turnMoveOutDays
                  ? messages.valueMustBeGreaterThan1
                  : messages.required
              }
              min={0}
            />
          ) : (
            turnMoveOutDays
          )}
        </SetupGridItem>

        <SetupGridItem
          label={<FormattedMessage {...messages.cashPrepaidGLCode} />}
        >
          {editMode ? (
            <TextInput
              name="cashPrepaidGLCode"
              value={cashPrepaidGLCode}
              editMode={editMode}
              onChange={onChange}
            />
          ) : (
            cashPrepaidGLCode || '---'
          )}
        </SetupGridItem>

        <SetupGridItem
          label={<FormattedMessage {...messages.renewalProrate} />}
        >
          {editMode ? (
            <StyledSelect
              id="renewal-prorate-method"
              name="renewalProrateMethodId"
              className="form-control"
              value={renewalProrateMethodId}
              onChange={onChange}
              placeholder="Renewal Pro-Rate"
            >
              {getProrateOptions('renewal')}
            </StyledSelect>
          ) : (
            getCurrentProrateMethod(renewalProrateMethodId)
          )}
        </SetupGridItem>

        {moveOutProratedRentAdminSetting && (
          <SetupGridItem
            label={<FormattedMessage {...messages.moveOutProrate} />}
            TooltipProps={
              prorateRentsForMoveOuts
                ? {
                    title: (
                      <Box>
                        <Box mb={2}>
                          <Typography variant="bodySmall">
                            <FormattedMessage
                              {...messages.moveOutProrateDesc}
                            />
                          </Typography>
                        </Box>
                        <Stack gap={2}>
                          <Box>
                            <Typography variant="label">
                              <FormattedMessage {...messages.yesFullMonth} />
                            </Typography>{' '}
                            <Typography variant="bodySmall">
                              <FormattedMessage
                                {...messages.yesFullMonthDesc}
                              />
                            </Typography>{' '}
                          </Box>
                          <Box>
                            <Typography variant="label">
                              <FormattedMessage {...messages.yesStartOfMonth} />
                            </Typography>{' '}
                            <Typography variant="bodySmall">
                              <FormattedMessage
                                {...messages.yesStartOfMonthDesc}
                              />
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="label">
                              <FormattedMessage {...messages.noNever} />
                            </Typography>{' '}
                            <Typography variant="bodySmall">
                              <FormattedMessage {...messages.noNeverDesc} />
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    ),
                  }
                : null
            }
          >
            {editMode ? (
              <StyledSelect
                id="moveOut-prorate-method"
                name={
                  prorateRentsForMoveOuts
                    ? 'moveOutProrateMethodId'
                    : 'deprecated_moveOutProrateMethodId'
                }
                className="form-control"
                value={currentMoveOutProrateMethodId}
                onChange={onChange}
                placeholder={intl.formatMessage(
                  messages.moveOutProratePlaceholder,
                )}
              >
                {prorateRentsForMoveOuts
                  ? getMoveOutProrateOptions()
                  : getProrateOptions('moveOut')}
              </StyledSelect>
            ) : prorateRentsForMoveOuts ? (
              getCurrentMoveOutProrateMethod(currentMoveOutProrateMethodId)
            ) : (
              getCurrentProrateMethod(currentMoveOutProrateMethodId)
            )}
          </SetupGridItem>
        )}

        <SetupGridItem
          label={<FormattedMessage {...messages.roundProrateRents} />}
        >
          {editMode ? (
            <Switch
              inputProps={{
                'aria-label': intl?.formatMessage(messages.roundProrateRents),
              }}
              checked={roundProratedRents}
              onChange={handleRoundProratedRentsChange}
            />
          ) : (
            <FormattedMessage
              {...messages?.[
                roundProratedRents
                  ? 'yesRoundProrateRents'
                  : 'noRoundProrateRents'
              ]}
            />
          )}
        </SetupGridItem>

        {showLeaseExpirationLimits && (
          <SetupGridItem
            aria-label="Lease Expiration Limits Setting"
            label={
              <FormattedMessage {...messages.leaseExpirationLimitsLabel} />
            }
          >
            <Stack direction={'row'} spacing={4}>
              {editMode ? (
                <Switch
                  inputProps={{
                    'aria-label': 'Lease Expiration Limits Setting',
                  }}
                  checked={isLeaseExpirationLimitsActive}
                  onChange={handleLeaseExpirationLimitsToggle}
                  disabled={isUpdateLeaseExpirationLimitLoading}
                />
              ) : (
                <OnOffTypography on={isLeaseExpirationLimitsActive}>
                  <FormattedMessage {...getLeaseExpirationLimit()} />
                </OnOffTypography>
              )}
              <ManageLeaseExpirationLimitsLink
                organizationId={organizationId}
                propertyId={id}
                history={history}
              />
            </Stack>
          </SetupGridItem>
        )}

        {adverseActionLetter && (
          <SetupGridItem
            aria-label="Adverse Action Deadline Setting"
            label={
              <FormattedMessage {...messages.adverseActionDeadlineDaysLabel} />
            }
            TooltipProps={{
              title: adverseLetterDaysTooltipMessage,
              children: <AlertInfoIcon />,
            }}
          >
            {editMode ? (
              <TextInput
                name="config.adverseActionDeadlineDays"
                value={adverseActionDeadlineDays ?? 10}
                editMode={editMode}
                onChange={onChange}
                type="number"
                required
                validateFunction={(value) =>
                  value !== '' && value >= 1 && value <= 999 && value % 1 === 0
                }
                message={adverseActionDeadlineDaysValidationMessage}
                min={1}
                max={999}
              />
            ) : (
              `${adverseActionDeadlineDaysText} (${
                adverseActionDeadlineDays ?? 10
              })`
            )}
          </SetupGridItem>
        )}

        {quoteExpirationDate && (
          <SetupGridItem
            label={<FormattedMessage {...messages.quoteExpirationDate} />}
          >
            {editMode ? (
              <StyledSelect
                name="quoteExpTime"
                className="form-control"
                value={quoteExpTime}
                onChange={onChange}
                placeholder="Quote Expiration Date"
                data-testid="quote-exp-select"
              >
                {getQuoteExpirationOptions()}
              </StyledSelect>
            ) : (
              <Typography data-testid="quote-exp-value">
                {formatQuoteExpirationTime(quoteExpTime)}
              </Typography>
            )}
          </SetupGridItem>
        )}

        {showAutoChargeMtmFeesSetting && (
          <SetupGridItem
            aria-label="Auto Charge Month-to-Month Fees Setting"
            label={<FormattedMessage {...messages.autoChargeMtmFeesLabel} />}
          >
            {editMode ? (
              <Switch
                inputProps={{
                  'aria-label': 'Auto Charge Month-to-Month Fees Setting',
                }}
                checked={isAutoChargeMtmFeesOn}
                onChange={handleAutoChargeMtmFeesChange}
              />
            ) : (
              <OnOffTypography on={isAutoChargeMtmFeesOn}>
                <FormattedMessage {...getAutoChargeMtmFeesTextValue()} />
              </OnOffTypography>
            )}
          </SetupGridItem>
        )}
        <SetupGridItem
          aria-label={`${intl.formatMessage(
            messages.successiveLeaseTerm,
          )} Setting`}
          label={<FormattedMessage {...messages.successiveLeaseTerm} />}
        >
          {editMode ? (
            <StyledSelect
              name="config.successiveLeaseTerm"
              className="form-control"
              value={successiveLeaseTerm}
              onChange={onChange}
              placeholder={intl.formatMessage(messages.successiveLeaseTerm)}
              data-testid="successive-lease-term-select"
            >
              {getSuccessiveLeaseTermOptions(intl, messages)}
            </StyledSelect>
          ) : (
            <Typography data-testid="successive-lease-term">
              <FormattedMessage
                {...messages[SUCCESSIVE_LEASE_TERM_MAP[successiveLeaseTerm]]}
              />
            </Typography>
          )}
        </SetupGridItem>
        <FortressPaymentsConfigWrapper
          flagIsOn={fortressPaymentsPropertyConfiguration}
          usesFortressPayments={usesFortressPayments}
        />

        {housingAssistanceVouchersFlag && (
          <SetupGridItem
            label={<FormattedMessage {...messages.housingAssistanceVouchers} />}
          >
            {editMode ? (
              <StyledSelect
                id="housing-assistance-vouchers"
                name="housingAssistanceVouchers"
                className="form-control"
                value={housingAssistanceVouchers}
                onChange={handleHousingAssistanceVoucherChange}
                placeholder="Housing Assistance Vouchers"
                data-testid="housing-assistance-vouchers-select"
                disabled={!doesPropertyHaveHudOrRd}
              >
                {getHousingAssistanceVoucherOptions()}
              </StyledSelect>
            ) : (
              <Typography
                data-testid="housing-assistance-vouchers-value"
                color={!doesPropertyHaveHudOrRd && 'text.secondary'}
              >
                {formatHousingAssistanceVoucher(housingAssistanceVouchers)}
              </Typography>
            )}
          </SetupGridItem>
        )}

        {utilityCompanyNameOnReimbursementChecksFlag &&
          ['Affordable', 'Mixed'].includes(
            appContext?.selectedProperty?.propertyClass?.name,
          ) && (
            <SetupGridItem
              label={
                <FormattedMessage
                  {...messages.addUtilityNameToUtilityReimbursementChecks}
                />
              }
            >
              {editMode ? (
                <>
                  <Switch
                    label={propertyDetails.electricCompany}
                    inputProps={{
                      'aria-label': intl.formatMessage(
                        messages.addUtilityNameToUtilityReimbursementChecks,
                      ),
                    }}
                    disabled={!propertyDetails.electricCompany}
                    checked={addUtilityNameToUtilityReimbursementChecks}
                    onChange={(event) =>
                      onChange({
                        target: {
                          name: 'config.addUtilityNameToUtilityReimbursementChecks',
                          value: event.target.checked,
                        },
                      })
                    }
                  />
                </>
              ) : (
                <Typography>
                  <FormattedMessage
                    {...(addUtilityNameToUtilityReimbursementChecks
                      ? messages.yes
                      : messages.no)}
                  />
                  {addUtilityNameToUtilityReimbursementChecks && (
                    <span>
                      {' - '}
                      {propertyDetails.electricCompany}
                    </span>
                  )}
                </Typography>
              )}
            </SetupGridItem>
          )}
      </Grid>
    </Card>
  );
};

export function FortressPaymentsConfigWrapper({
  flagIsOn = false,
  usesFortressPayments = false,
}) {
  return flagIsOn && usesFortressPayments && <FortressPaymentsConfig />;
}

export function PaymentProviderField({
  isDomusoActive = false,
  usesFortressPayments = false,
  isPayLeaseActive = false,
}) {
  const getPaymentProviderText = () => {
    const providers = [];

    if (isDomusoActive) providers.push('Domuso');
    if (usesFortressPayments) providers.push('Fortress');
    if (isPayLeaseActive) providers.push('PayLease');

    return providers.length === 0 ? '---' : providers.join(', ');
  };

  return (
    <SetupGridItem label={<FormattedMessage {...messages.paymentProvider} />}>
      <Typography>{getPaymentProviderText()}</Typography>
    </SetupGridItem>
  );
}

export default LeaseSetup;
