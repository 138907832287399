import { FormattedMessage } from 'react-intl';

// Components
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  SetupLabel,
  SetupSwitch,
  SetupTitle,
  SetupValue,
  AnnualRecertMonthPicker,
} from '../shared';
import ProgramPassbookRate from '../ProgramPassbookRate';
import SetupGridItem from '../../../SetupGridItem';
import type { PassbookRate } from '../shared';

// Constants
import messages from './messages';
import messagesAffordableSetup from '../../messages';

interface RDFlags {
  passbookRate: boolean;
  rolloverIncomeAndAssets: boolean;
}

interface RDValues {
  RDProgramType: Object;
  RDProjectName: string;
  RDBorrowerId: string;
  RDProjectNumber: string;
  RDProjectType: Object;
  passbookRates: Array<PassbookRate>;
  autoRolloverIncomeAndAssets: boolean;
  flags: RDFlags;
}

type Props = {
  isLoadingPassbookRateData: boolean,
  editMode: boolean,
  onChange: Function,
  values: RDValues,
};

function RDSetup({
  editMode,
  onChange,
  values,
  isLoadingPassbookRateData,
}: Props) {
  const {
    RDBorrowerId,
    RDProjectName,
    RDProjectNumber,
    RDProjectType,
    RDProgramType,
    isRDRAEnabled,
    passbookRates,
    autoRolloverIncomeAndAssets,
    RDAnnualRecertMonth,
    flags: { passbookRate = false, rolloverIncomeAndAssets },
  } = values;

  const handleRDAnnualRecertMonthChange = (date: Object, validate: Object) => {
    onChange({
      target: { name: 'setup.RDAnnualRecertMonth', value: date },
    });
  };

  return (
    <Grid container spacing={2}>
      <SetupTitle>
        <FormattedMessage {...messages.RDSetupTitle} />
      </SetupTitle>
      <Grid item xs={12} sm={6}>
        <SetupLabel {...messages.RDProgramType} />
        <SetupValue
          editMode={editMode}
          name="setup.RDProgramType"
          value={RDProgramType?.description}
          placeholder=""
          editable={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SetupLabel {...messages.RDBorrowerId} />
        <SetupValue
          editMode={editMode}
          name="setup.RDBorrowerId"
          value={RDBorrowerId}
          onChange={onChange}
          placeholder=""
          maxLength={255}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SetupLabel {...messages.RDProjectName} />
        <SetupValue
          editMode={editMode}
          name="setup.RDProjectName"
          value={RDProjectName}
          onChange={onChange}
          placeholder=""
          maxLength={255}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SetupLabel {...messages.RDRentalAssistance} />
        <SetupSwitch
          switchId="rd-rental-assistance-switch"
          editMode={editMode}
          name="setup.isRDRAEnabled"
          value={isRDRAEnabled}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SetupLabel {...messages.RDProjectNumber} />
        <SetupValue
          editMode={editMode}
          name="setup.RDProjectNumber"
          value={RDProjectNumber}
          onChange={onChange}
          placeholder=""
          maxLength={255}
        />
      </Grid>
      {rolloverIncomeAndAssets && (
        <SetupGridItem
          label={
            <FormattedMessage
              {...messagesAffordableSetup.autoRolloverIncomeAndAssets}
            />
          }
          TooltipProps={{
            title: (
              <FormattedMessage
                {...messagesAffordableSetup.autoRolloverIncomeAndAssetsDescription}
              />
            ),
          }}
        >
          <SetupSwitch
            switchId={'rd-auto-rollover-income-and-assets-switch'}
            editMode={editMode}
            name="setup.RDAutoRolloverIncomeAndAssets"
            disabled={!editMode}
            value={autoRolloverIncomeAndAssets}
            onChange={onChange}
          />
        </SetupGridItem>
      )}
      <Grid item xs={12} sm={6}>
        <SetupLabel {...messages.RDProjectType} />
        <SetupValue
          editMode={editMode}
          name="setup.RDProjectType"
          value={RDProjectType?.description}
          placeholder=""
          editable={false}
        />
      </Grid>
      <SetupGridItem
        label={
          <FormattedMessage
            {...messagesAffordableSetup.annualRecertMonth}
            values={{ program: 'RD' }}
          />
        }
      >
        <AnnualRecertMonthPicker
          handleAnnualRecertMonthChange={handleRDAnnualRecertMonthChange}
          editMode={editMode}
          programName={'RD'}
          value={RDAnnualRecertMonth}
        />
      </SetupGridItem>
      {passbookRate && (
        <Grid item xs={12}>
          <ProgramPassbookRate
            key={'RD_PassbookRates'}
            programName={'RD'}
            isLoadingPassbookRateData={isLoadingPassbookRateData}
            passbookRates={passbookRates}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default RDSetup;
