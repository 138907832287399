import { useMemo, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';

// Components
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  Modal,
  ModalConfirm,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import {
  PlusIcon,
  PencilIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';

// Hooks
import useReactHookFormProps from '../../../hooks/useReactHookFormProps';
import {
  useEditHousingAssistanceVoucher,
  useCreateHousingAssistanceVoucher,
  useDeleteHousingAssistanceVoucher,
} from '../hooks.useMutations';

// Others
import messages from '../messages';
import moment from 'moment';
import { disableAllDaysOfTheMonthExceptFirst } from '../../../utils/date-helpers';

const confirmModalPropsDiscardChanges = ({
  closeConfirmModal,
  handleClose,
  openConfirm,
}) => ({
  title: 'Discard Changes?',
  onClose: closeConfirmModal,
  open: openConfirm,
  actionsProps: [
    {
      children: 'Cancel',
      onClick: closeConfirmModal,
    },
    {
      children: 'Discard Changes',
      onClick: () => {
        handleClose();
        closeConfirmModal();
      },
    },
  ],
});

const ManageHousingAssistanceVoucherModals = ({
  organizationId,
  propertyId,
  householdId,
  refetchVouchers,
  intl,
  modalState,
  modalType,
  open,
  openConfirm,
  openConfirmModal,
  handleClose,
  closeConfirmModal,
}) => {
  const {
    mutate: createHousingAssistanceVoucherMutate,
    isLoading: createHousingAssistanceVoucherMutationIsLoading,
  } = useCreateHousingAssistanceVoucher({
    organizationId,
    propertyId,
    householdId,
    options: {
      onSuccess: (res) => {
        toastr.success('Success', res.message);
        handleClose();
        refetchVouchers();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const {
    mutate: editHousingAssistanceVoucherMutate,
    isLoading: editHousingAssistanceVoucherMutationIsLoading,
  } = useEditHousingAssistanceVoucher({
    organizationId,
    propertyId,
    householdId,
    options: {
      onSuccess: (res) => {
        if (res.data?.transactions) {
          toastr.info(
            'Info',
            'Transactions have been created for this voucher',
          );
        }
        toastr.success('Success', res.message);
        handleClose();
        refetchVouchers();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const {
    mutate: deleteHousingAssistanceVoucherMutate,
    isLoading: deleteHousingAssistanceVoucherMutationIsLoading,
  } = useDeleteHousingAssistanceVoucher({
    organizationId,
    propertyId,
    householdId,
    options: {
      onSuccess: (res) => {
        toastr.success('Success', res.message);
        closeConfirmModal();
        refetchVouchers();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const ReactHookFormProps = useReactHookFormProps({});
  const { reset, getValues, isValid, isDirty } = ReactHookFormProps;

  useEffect(() => {
    const { voucherSource, voucherAmount, voucherStartDate, voucherEndDate } =
      modalState?.voucher || {};
    const defaultValues = {
      editVoucher: {
        voucherSource: voucherSource || '',
        voucherAmount: voucherAmount || '',
        voucherStartDate: voucherStartDate ? moment(voucherStartDate) : null,
        voucherEndDate: voucherEndDate ? moment(voucherEndDate) : null,
      },
      createVoucher: {
        voucherSource: '',
        voucherAmount: '',
        voucherStartDate: null,
        voucherEndDate: null,
      },
    }[modalType];

    reset(defaultValues);
  }, [reset, modalType, modalState]);

  /**
   * Returns the form fields to display on the create/edit modal
   * -
   * @param {*} param0
   * @returns
   */
  const formFields = useCallback(
    ({ intl }) => {
      let disabled = false;
      return [
        {
          variant: 'text',
          name: 'voucherSource',
          label: <FormattedMessage {...messages.voucherSource} />,
          rules: {
            required: true,
          },
          disabled,
          GridProps: {
            xs: 6,
          },
        },
        {
          variant: 'number',
          name: 'voucherAmount',
          label: <FormattedMessage {...messages.voucherAmount} />,
          adornment: '$',
          rules: {
            required: true,
            min: 0,
            step: 1,
          },
          disabled: false,
          GridProps: {
            xs: 6,
          },
        },
        {
          variant: 'date',
          name: 'voucherStartDate',
          label: <FormattedMessage {...messages.voucherStartDate} />,
          views: ['month', 'year'],
          format: 'YYYY-MM-DD',
          placeholder: '2015-01-01',
          shouldDisableDate: (date) =>
            disableAllDaysOfTheMonthExceptFirst(date),
          rules: {
            required: true,
            validate: (date) => {
              let isValid = false;
              if (date) {
                const dayOfMonth = new Date(date).getDate();
                isValid = dayOfMonth === 1;
              }
              if (!isValid) {
                return intl.formatMessage({
                  id: 'App.HousingAssistanceVouchers.StartDateError',
                });
              }
              return isValid;
            },
          },
          disabled,
          GridProps: {
            xs: 6,
          },
        },
        {
          variant: 'date',
          name: 'voucherEndDate',
          label: <FormattedMessage {...messages.voucherEndDate} />,
          views: ['month', 'year'],
          format: 'YYYY-MM-DD',
          placeholder: '2015-01-31',
          shouldDisableDate: (date) => {
            if (date) {
              const _date = new Date(date);
              const lastDay = new Date(
                _date.getFullYear(),
                _date.getMonth() + 1,
                0,
              ).getDate();
              return _date.getDate() !== lastDay;
            }
          },
          rules: {
            required: false,
            validate: (date) => {
              let isValid = true;
              if (date) {
                const _date = new Date(date);
                const lastDay = new Date(
                  _date.getFullYear(),
                  _date.getMonth() + 1,
                  0,
                ).getDate();
                isValid = _date.getDate() === lastDay;
              }
              if (!isValid) {
                return intl.formatMessage({
                  id: 'App.HousingAssistanceVouchers.EndDateError',
                });
              }
              return isValid;
            },
          },
          disabled,
          GridProps: {
            xs: 6,
          },
        },
      ];
    },
    // Add dependencies in case the fields need to change based on some condition for the future
    [],
  );

  const { confirmModalProps, modalProps, formProps } = useMemo(() => {
    const props = {
      modalProps: {},
      formProps: {},
      confirmModalProps: {},
    };

    switch (modalType) {
      case 'createVoucher':
        props.modalProps = {
          title: <FormattedMessage {...messages.addNewVoucher} />,
          icon: <PlusIcon />,
          ContentProps: {
            sx: {
              maxWidth: '600px',
            },
          },
          actionsProps: [
            {
              children: 'Cancel',
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: 'Save',
              submitButton: true,
              isSubmitting: createHousingAssistanceVoucherMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  voucherSource: values.voucherSource,
                  voucherAmount: values.voucherAmount,
                  voucherStartDate: values.voucherStartDate,
                  voucherEndDate: values.voucherEndDate,
                };

                createHousingAssistanceVoucherMutate({ payload });
              },
            },
          ],
        };
        props.formProps = {
          fields: formFields({ intl }),
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });

        break;
      case 'editVoucher':
        props.modalProps = {
          title: <FormattedMessage {...messages.editVoucher} />,
          icon: <PencilIcon />,
          ContentProps: {
            sx: {
              maxWidth: '600px',
            },
          },
          actionsProps: [
            {
              children: 'Cancel',
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: 'Save',
              submitButton: true,
              isSubmitting: editHousingAssistanceVoucherMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const housingAssistanceVoucherId = modalState?.voucher?.id;
                const payload = {
                  voucherSource: values.voucherSource,
                  voucherAmount: values.voucherAmount,
                  voucherStartDate: values.voucherStartDate,
                  voucherEndDate: values.voucherEndDate,
                };

                editHousingAssistanceVoucherMutate({
                  housingAssistanceVoucherId,
                  payload,
                });
              },
            },
          ],
        };
        props.formProps = {
          fields: formFields({ intl }),
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });

        break;
      case 'deleteVoucher':
        props.modalProps = {};
        props.formProps = {};
        props.confirmModalProps = {
          title: <FormattedMessage {...messages.deleteVoucher} />,
          children: (
            <>
              <Typography>
                <FormattedMessage {...messages.deleteVoucherConfirmation} />
              </Typography>
            </>
          ),
          actionsProps: [
            {
              children: 'Cancel',
              onClick: closeConfirmModal,
            },
            {
              children: 'Yes',
              submitButton: true,
              isSubmitting: deleteHousingAssistanceVoucherMutationIsLoading,
              onClick: () => {
                deleteHousingAssistanceVoucherMutate({
                  housingAssistanceVoucherId: modalState?.voucher?.id,
                });
              },
            },
          ],
        };
        break;

      default:
        break;
    }

    return props;
  }, [
    // mutations
    createHousingAssistanceVoucherMutate,
    createHousingAssistanceVoucherMutationIsLoading,
    editHousingAssistanceVoucherMutate,
    editHousingAssistanceVoucherMutationIsLoading,
    deleteHousingAssistanceVoucherMutate,
    deleteHousingAssistanceVoucherMutationIsLoading,
    // form
    getValues,
    isDirty,
    isValid,
    formFields,
    // modal
    handleClose,
    closeConfirmModal,
    modalState,
    modalType,
    openConfirm,
    openConfirmModal,
    intl,
  ]);

  return (
    <>
      <Modal open={open} onClose={handleClose} {...modalProps}>
        <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
      </Modal>
      <ModalConfirm open={openConfirm} {...confirmModalProps} />
    </>
  );
};

export default ManageHousingAssistanceVoucherModals;
