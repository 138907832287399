import React, { useRef, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppBarDrawer } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { socketConnect, socketDisconnect } from '../../containers/App/actions';
import { getEnvironmentName } from '../../containers/App/selectors';
import ToolbarChildren from './ToolbarChildren';
import DrawerContent from './DrawerContent';
import useAppBarNavDrawer from './hooks';
import { drawerWidth } from './constants';

const AppBarNavDrawer = ({
  actions,
  children,
  environmentName,
  userProperties,
  paymentProvider,
  navigationOptions,
  locale,
  selectedPropertySubjournals,
  history,
}) => {
  const hoverTimeout = useRef(null);
  const {
    drawerHovered,
    flags,
    fullName,
    handleUserMenuClose,
    handleUserMenuToggle,
    logOut,
    navOptionsArray,
    onSelectPortfolioSummary,
    onSelectProperty,
    open,
    selectedProperty,
    setDrawerHovered,
    setOpen,
    showNavText,
    useMobileDrawer,
    userMenuAnchorRef,
    userMenuOpen,
  } = useAppBarNavDrawer({
    actions,
    navigationOptions,
    paymentProvider,
    locale,
    selectedPropertySubjournals,
    history,
  });

  const isRdProperty = useMemo(() => {
    return selectedProperty?.pap.some(
      (item) => item.masterAffordableProgram?.name === 'RD',
    );
  }, [selectedProperty]);

  return (
    <>
      <AppBarDrawer
        id={'app-bar'}
        DrawerContainerProps={{
          component: 'nav',
          sx: {
            width: { sm: drawerWidth.closed },
            flexShrink: { sm: 0 },
          },
          'aria-label': 'navigation links',
        }}
        SwipeableDrawerProps={{
          variant: 'temporary',
          open: useMobileDrawer ? open : false,
          onClose: () => setOpen(false),
          onOpen: () => setOpen(true),
          ModalProps: {
            keepMounted: useMobileDrawer, // Better open performance on mobile.
          },
          sx: {
            display: flags.leftsideNavButton
              ? { sm: 'block', md: 'none' }
              : { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              maxWidth: 'calc(100vw - 56px)',
              width: drawerWidth.open,
            },
          },
        }}
        DrawerProps={{
          variant: 'permanent',
          onMouseEnter: () => {
            clearTimeout(hoverTimeout.current);
            hoverTimeout.current = setTimeout(() => {
              setDrawerHovered(true);
            }, 300);
          },
          onMouseLeave: () => {
            clearTimeout(hoverTimeout.current);
            setDrawerHovered(false);
          },
          sx: {
            display: flags.leftsideNavButton
              ? { xs: 'none', md: 'block' }
              : { xs: 'none', sm: 'block' },
            overflowX: 'hidden',
            '& .MuiDrawer-paper': {
              transition: ({ transitions: { create, duration, easing } }) =>
                create('transform', {
                  duration: duration.shortest,
                  easing: easing.sharp,
                }),
              boxSizing: 'border-box',
              width: drawerWidth.open,
              transform: () => {
                if (!useMobileDrawer)
                  return `translateX(${
                    drawerHovered || open
                      ? drawerWidth.open
                      : `calc(-${drawerWidth.open}px + ${drawerWidth.closed}px)`
                  })`;

                return 'translateX(0px)';
              },
            },
          },
        }}
        sx={{
          width: '100%',
          ml: 0,
          zIndex: ({ zIndex }) => ({
            sm: zIndex.drawer + 1,
          }),
          backgroundColor:
            environmentName === 'development'
              ? 'secondary.dark'
              : 'primary.darker',
        }}
        toolbarChildren={
          <ToolbarChildren
            open={open}
            setOpen={setOpen}
            isXs={useMobileDrawer}
            userMenuAnchorRef={userMenuAnchorRef}
            userMenuOpen={userMenuOpen}
            handleUserMenuToggle={handleUserMenuToggle}
            handleUserMenuClose={handleUserMenuClose}
            logout={logOut}
            selectedProperty={selectedProperty}
            onSelectProperty={onSelectProperty}
            onSelectPortfolioSummary={onSelectPortfolioSummary}
            fullName={fullName}
            userProperties={userProperties}
          />
        }
      >
        <DrawerContent
          showNavText={showNavText}
          drawerWidth={drawerWidth}
          environmentName={environmentName}
          navOptionsArray={navOptionsArray}
          isXs={useMobileDrawer}
          isRdProperty={isRdProperty}
          flags={{
            mincTransactionScreen: flags.mincTransactionScreen,
          }}
        />
      </AppBarDrawer>
      {children({ open, drawerWidth })}
    </>
  );
};

const mapStateToProps = (state) => {
  const { app, languageProvider } = state;
  return {
    environmentName: getEnvironmentName(state),
    navigationOptions: app?.navigationOptions,
    selectedPropertySubjournals: app?.selectedPropertySubjournals,
    paymentProvider: app?.propertyPaymentMethod,
    userProperties: app?.currentUser?.user?.properties ?? [],
    locale: languageProvider?.locale,
  };
};
const mapDispatchToProps = (dispatch: any): Object => {
  const bindedActions = bindActionCreators(
    {
      socketConnect,
      socketDisconnect,
    },
    dispatch,
  );
  return { actions: bindedActions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppBarNavDrawer));
