/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewProperty.AffordableSetup.Title',
    defaultMessage: 'Affordable Setup',
  },
  stateProjectIdXml: {
    id: 'App.ViewProperty.AffordableSetup.StateProjectIdXml',
    defaultMessage: 'State / Project ID (XML)*',
  },
  stateProjectIdTicHec: {
    id: 'App.ViewProperty.AffordableSetupUpdate.StateProjectIdTicHec',
    defaultMessage: 'State / Project ID (TIC/HEC)*',
  },
  hudSubsidyType: {
    id: 'App.ViewProperty.AffordableSetupUpdate.HUDSubsidyType',
    defaultMessage: 'HUD Subsidy Type',
  },
  hudProjectNumber: {
    id: 'App.ViewProperty.AffordableSetupUpdate.HUDProjectNumber',
    defaultMessage: 'HUD Project Number',
  },
  affordablePrograms: {
    id: 'App.ViewProperty.AffordableSetupUpdate.AffordablePrograms',
    defaultMessage: 'Affordable Programs',
  },
  hudSecondarySubsidyType: {
    id: 'App.ViewProperty.AffordableSetup.HUDSecondarySubsidyType',
    defaultMessage: 'HUD Secondary Subsidy Type',
  },
  hudContractNumber: {
    id: 'App.ViewProperty.AffordableSetup.HUDContractNumber',
    defaultMessage: 'HUD Contract Number',
  },
  initialCertForNonHUD: {
    id: 'App.ViewProperty.AffordableSetup.InitialCertForNonHUD',
    defaultMessage: 'Initial Certs for LIHTC and Other Programs',
  },
  initialCertForNonHUDTip: {
    id: 'App.ViewProperty.AffordableSetupUpdate.InitialCertForNonHUD',
    defaultMessage:
      'This feature will allow users to start and Initital Certification from the LIHTC and Other Programs Qualification tab for any households that do not have an Initial Cert record completed in Fortress.',
  },
  altXMLPropertyName: {
    id: 'App.ViewProperty.AffordableSetupUpdate.AltXMLPropertyName',
    defaultMessage: 'Affordable Property Name (XML)',
  },
  overrideQualification: {
    id: 'App.ViewProperty.AffordableSetupUpdate.OverrideQualification',
    defaultMessage: 'Override Qualification',
  },
  altXMLProjectName: {
    id: 'App.ViewProperty.AffordableSetupUpdate.AltXMLProjectName',
    defaultMessage: 'Affordable Project Name (XML)',
  },
  altXMLProjectNameTooltip: {
    id: 'App.ViewProperty.AffordableSetupUpdate.AltXMLProjectNameTooltip',
    defaultMessage:
      'To change the XML name of different sites within your project, navigate to the Manage Buildings screen.',
  },
  autoRolloverIncomeAndAssets: {
    id: 'App.ViewProperty.AffordableSetupUpdate.AllPrograms.AutoRolloverIncomeAndAssets',
    defaultMessage: 'Auto-Rollover Income and Assets',
  },
  autoRolloverIncomeAndAssetsDescription: {
    id: 'App.ViewProperty.AffordableSetupUpdate.AllPrograms.AutoRolloverIncomeAndAssetsDescription',
    defaultMessage:
      "When this feature is activated, each time a new recertification process begins, the data from the last completed recertification will be automatically transferred to the income and asset forms. You'll need to review and confirm each form before it's marked as 'complete'.",
  },
  annualRecertMonth: {
    id: 'App.ViewProperty.AffordableSetupUpdate.AnnualRecertMonth',
    defaultMessage: '{program} Annual Recertification Month',
  },
  affordableWaitlist: {
    id: 'App.ViewProperty.AffordableSetup.AffordableWaitlist',
    defaultMessage: 'Affordable Waitlist',
  },
  affordableWaitlistTooltip: {
    id: 'App.ViewProperty.AffordableSetup.AffordableWaitlistTooltip',
    defaultMessage:
      'Turning ON the Affordable Waitlist will place applicants on the waitlist whether they are entered manually or through portal in order to track and audit the selection of applicants from the waitlist.',
  },
});

export default messages;
