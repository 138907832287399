import { Table } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import React from 'react';
import useBaseTableProps from '../../../hooks/useBaseTableProps';

const RentForecastTable = (props) => {
  const {
    allColumnsHidden,
    columnOptions,
    count,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers,
    height,
    intl,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    totalCount,
  } = props;
  const baseTableProps = useBaseTableProps();

  return (
    <Table
      {...baseTableProps}
      allColumnsHidden={allColumnsHidden}
      columnOptions={columnOptions}
      count={count}
      dateState={dateState}
      filterState={filterState}
      filterTypeState={filterTypeState}
      filteredHeaders={filteredHeaders}
      footerRow={footerRow}
      handleColumnChange={handleColumnChange}
      handleDateSubmit={handleDateSubmit}
      handleFilterChange={handleFilterChange}
      handleFilterTypeChange={handleFilterTypeChange}
      handleSearchSubmit={handleSearchSubmit}
      handleSortChange={handleSortChange}
      headers={headers}
      height={height}
      intl={intl}
      name={name}
      order={order}
      orderBy={orderBy}
      rows={rows}
      searchState={searchState}
      selectedColumns={selectedColumns}
      totalCount={totalCount}
    />
  );
};

export default RentForecastTable;
