/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.RDLimitChanges.Title',
    defaultMessage: 'RD Limit Changes',
  },
  internalName: {
    id: 'App.RDLimitChanges.internalName',
    defaultMessage: 'Floor Plan',
  },
  marketingName: {
    id: 'App.RDLimitChanges.marketingName',
    defaultMessage: 'Marketing Name',
  },
  startDate: {
    id: 'App.RDLimitChanges.startDate',
    defaultMessage: 'Start Date',
  },
  noteRent: {
    id: 'App.RDLimitChanges.noteRent',
    defaultMessage: 'Note Rent',
  },
  newNoteRent: {
    id: 'App.RDLimitChanges.newNoteRent',
    defaultMessage: 'New Note Rent',
  },
  basicRent: {
    id: 'App.RDLimitChanges.basicRent',
    defaultMessage: 'Basic Rent',
  },
  newBasicRent: {
    id: 'App.RDLimitChanges.newBasicRent',
    defaultMessage: 'New Basic Rent',
  },
  rdUtilityAllowance: {
    id: 'App.RDLimitChanges.RDUtilityAllowance',
    defaultMessage: 'RD Utility Allowance',
  },
  newRdUtilityAllowance: {
    id: 'App.RDLimitChanges.newRDUtilityAllowance',
    defaultMessage: 'New RD Utility Allowance',
  },
  notes: {
    id: 'App.RDLimitChanges.notes',
    defaultMessage: 'Notes',
  },
  note1: {
    id: 'App.ManageFloorPlan.note1',
    defaultMessage:
      'Saving these gross rent changes will update all units assigned to these floor plans and open a Gross Rent Certification for all current residents assigned. A Gross Rent cert will be created every time this modal is saved.',
  },
  note2: {
    id: 'App.ManageFloorPlan.note2',
    defaultMessage:
      'Rent and Utility Allowance amounts include the most recent updates schedules or current ammount listed for that floor plan.',
  },
  noteRentSuccess: {
    id: 'App.ManageFloorPlan.noteRentSuccess',
    defaultMessage: 'New Note Rent is saved.',
  },
  basicRentSuccess: {
    id: 'App.ManageFloorPlan.basicRentSuccess',
    defaultMessage: 'New Basic Rent is saved.',
  },
  uaSuccess: {
    id: 'App.ManageFloorPlan.uaSuccess',
    defaultMessage: 'RD Utility Allowance is saved.',
  },
  allSuccess: {
    id: 'App.ManageFloorPlan.allSuccess',
    defaultMessage:
      'New Note Rent, New Basic Rent, RD Utility Allowance is saved.',
  },
});

export default messages;
