import moment from 'moment';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function dateDiffInDays(a: Date, b: Date): number {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const getDateDiffInDays = (a: Date, b: Date): number =>
  moment(a).diff(moment(b), 'days');

export const getIsPastDueDate = (dueDate: Date): boolean => {
  if (!dueDate) {
    return false;
  }
  const dueDateMoment = moment(new Date(dueDate));
  return moment.isMoment(dueDateMoment)
    ? getDate().isAfter(dueDateMoment.add(1, 'day'))
    : false;
};

export function dateDiffInMonths(a: Date, b: Date): number {
  const months = moment(b).diff(moment(a), 'months', true);
  return Math.floor(months);
}

export function formatDate(date: string, intl: Object) {
  if (date)
    return intl.formatDate(date, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
}

export function formatTime(time: string, intl: Object) {
  if (time) return intl.formatTime(time);
}

export const formatDateUTC = (date) => moment.utc(date).format('YYYY-MM-DD');

export const formatDateLocale = (date: Date, format: string = 'YYYY-MM-DD') =>
  date && format.length ? moment(date).local().format(format) : '';

/*BE returns dateTime strings in UTC, so initialize moment as utc then
 convert to local tz only, not actual date */
export const formatDateUTCToLocale = (date) =>
  moment.utc(date).local().format('YYYY-MM-DD');

export const getDateUTC = () => moment.utc();

export const getDate = () => moment();

export function isBetweenDates(
  testedDate: Date,
  startDate: Date,
  endDate: Date,
  precision?: string,
): boolean {
  return (
    startDate.isSameOrBefore(testedDate, precision) &&
    testedDate.isSameOrBefore(endDate, precision)
  );
}

export const isValidDate = (dateStr: string) =>
  typeof dateStr === 'string' && moment(dateStr).isValid();

export const validateDateRange = ({
  field,
  date,
  dateFrom,
  dateTo,
}: {
  field: string,
  date: Object,
  dateFrom: string,
  dateTo: string,
}) => {
  let momentFrom, momentTo, validDateFrom, validDateTo;
  if (field === 'dateFrom' && dateTo) {
    momentFrom = moment(date);
    momentTo = moment(dateTo);
    validDateFrom = date && momentFrom.isValid();
    validDateTo = dateTo && momentTo.isValid();
  } else if (field === 'dateTo' && dateFrom) {
    momentFrom = moment(dateFrom);
    momentTo = moment(date);
    validDateFrom = dateFrom && momentFrom.isValid();
    validDateTo = date && momentTo.isValid();
  }
  const datesFormatted = validDateFrom && validDateTo;
  const fromAfterTo = momentFrom && momentFrom.isAfter(momentTo);

  return { datesFormatted, fromAfterTo };
};

export const validateDateFormat = ({
  field,
  date,
}: {
  field: string,
  date: Object,
}) => {
  let dateErrors = {
    [`${field}Error`]: '',
  };

  if (!(date && moment(date).isValid())) {
    dateErrors = {
      [`${field}Error`]: 'Must be a valid date',
    };
  } else if (date && moment(date).isValid()) {
    dateErrors = {
      [`${field}Error`]: '',
    };
  }

  return dateErrors;
};

export const isToDateNotSameOrAfterFromDate = (
  fromDate?: string,
  toDate?: string,
) => {
  if (!fromDate || !toDate) return false;

  const momentFrom = moment(fromDate);
  const momentTo = moment(toDate);

  if (!momentFrom.isValid() || !momentTo.isValid()) return false;

  return !momentTo.isSameOrAfter(momentFrom);
};

export const sortByDateField = (
  data: Array<any>,
  dateFieldName: string,
  desc?: boolean = false,
) => {
  return data.sort((a, b) => {
    if (desc) {
      return moment.utc(b[dateFieldName]).diff(moment.utc(a[dateFieldName]));
    }
    return moment.utc(a[dateFieldName]).diff(moment.utc(b[dateFieldName]));
  });
};

export const disableAllDaysOfTheMonthExceptFirst = (date: Date) => {
  if (date) {
    const dayOfMonth = new Date(date).getDate();
    return dayOfMonth !== 1;
  }
};
