import { useMemo } from 'react';
import { CERTIFICATION_TYPE_DISPLAY_NAMES } from '../../constants/affordableCertificationTypes';

const useParseResults = ({ results }) => {
  return useMemo(() => {
    return results?.length
      ? results.map((result) => {
          const parsedResult = { ...result };
          if (result.hasOwnProperty('certificationType')) {
            parsedResult.certificationType =
              CERTIFICATION_TYPE_DISPLAY_NAMES[result.certificationType] ??
              result.certificationType;
          }
          return parsedResult;
        })
      : [];
  }, [results]);
};

export default useParseResults;
