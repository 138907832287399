import { useState, useEffect } from 'react';

/**
 * Hook to manage the checkboxes column state
 * ---
 * @TODO need to re-implement custom hook, since it doesn't behave as expected with filtering/sorting
 * @param {*} param0.results all the rows of the table
 * @param {*} param0.featureFlag a feature flag to show the checkboxes column
 * @returns
 */
const useCheckboxColumn = ({ results, featureFlag }) => {
  const [checkboxList, setCheckboxList] = useState([]);
  const initAndfillArrayWith = (value) =>
    [...Array(results?.length).keys()].map(() => value);
  useEffect(() => {
    setCheckboxList(initAndfillArrayWith(false));
  }, [results, featureFlag]);

  const handleSelectDeSelectAllRows = (event) => {
    event.persist();
    const checked = event.target.checked;
    setCheckboxList(
      checked ? initAndfillArrayWith(true) : initAndfillArrayWith(false),
    );
  };

  const handleSelectRow = (index) => {
    setCheckboxList((prev) =>
      prev.map((value, i) => (i === index ? !value : value)),
    );
  };

  const isAtLeastOneRowSelected =
    checkboxList.filter((value) => value).length > 0;

  return {
    checkboxList,
    handleSelectDeSelectAllRows,
    handleSelectRow,
    isAtLeastOneRowSelected,
  };
};

export default useCheckboxColumn;
