import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const HEADERS = [
  {
    id: 'headOfHousehold',
    label: <FormattedMessage {...messages.headOfHousehold} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
  },
  {
    id: 'unitNumber',
    label: <FormattedMessage {...messages.unitNumber} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
  },
  {
    id: 'certificationType',
    label: <FormattedMessage {...messages.certificationType} />,
    dataType: 'string',
    sortable: true,
    searchable: false,
    multiselect: true,
  },
  {
    id: 'effectiveDate',
    label: <FormattedMessage {...messages.effectiveDate} />,
    dataType: 'date',
    sortable: true,
    searchable: true,
  },
  {
    id: 'certificationCompletionDate',
    label: <FormattedMessage {...messages.certificationCompletionDate} />,
    dataType: 'date',
    sortable: true,
    searchable: true,
  },
  {
    id: 'status',
    label: <FormattedMessage {...messages.status} />,
    dataType: 'string',
    sortable: true,
    searchable: false,
  },
];

export const PROPERTY_PATH_MAP = {
  headOfHousehold: {
    path: 'headOfHousehold',
    dataType: 'string',
  },
  unitNumber: {
    path: 'unitNumber',
    dataType: 'string',
  },
  certificationType: {
    path: 'certificationType',
    dataType: 'string',
  },
  effectiveDate: {
    path: 'effectiveDate',
    dataType: 'date',
  },
  certificationCompletionDate: {
    path: 'certificationCompletionDate',
    dataType: 'date',
  },
  status: {
    path: 'status',
    dataType: 'string',
  },
};

export const LIMIT_PER_PAGE = 20;
