import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SubmitButton } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const GenerateAllRDGRCerts = ({ disabled, intl, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const { organizationId, id: propertyId } = useSelectedProperty();
  // TODO - ENG-6601
  // const downloadAllRdGrAsMutation = useDownloadAll59AsMutation()

  // TODO - ENG-6601
  return (
    <SubmitButton
      variant={'shout'}
      // isSubmitting={downloadAllRdGrAsMutation.isLoading}
      // onClick={downloadAllRdGrAsMutation.mutate}
      disabled={disabled}
      {...props}
    >
      <FormattedMessage {...messages.generateAllRdGrCerts} />
    </SubmitButton>
  );
};

export default GenerateAllRDGRCerts;
