import {
  Box,
  Tooltip,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  CircleCheckIcon,
  AlertIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';

const StatusCard = ({ label, part, tooltip, selected, onClick, success }) => {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <Box
        sx={{
          border: '1px solid',
          borderColor: selected ? palette.dark.grey : palette.lighter.grey,
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: '8px',
          padding: '16px',
          width: '175px',
        }}
        onClick={onClick}
      >
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: success
                ? palette.lighter.green
                : palette.lighter.red,
              borderRadius: '50%',
              display: 'flex',
              height: '32px',
              justifyContent: 'center',
              marginBottom: '8px',
              width: '32px',
            }}
          >
            {success ? (
              <CircleCheckIcon color="success" />
            ) : (
              <AlertIcon color="warning" />
            )}
          </Box>
          <Typography
            variant="titleSmall"
            color={selected ? palette.dark.grey : palette.main.grey}
          >
            {label}
          </Typography>
        </Box>

        <Typography
          variant="bodyMedium"
          color={selected ? palette.dark.grey : palette.main.grey}
        >
          {part}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default StatusCard;
