import { defineMessages } from 'react-intl';
export const RECERT_CERT_TYPE = 'RECERT';
export const RECERT_UT_CERT_TYPE = 'RECERT_UT';
export const INITIAL_CERT_TYPE = 'INITIAL';
export const INITIAL_UT_CERT_TYPE = 'INITIAL_UT';
export const MOVE_IN_CERT_TYPE = 'MOVE_IN';
export const MOVE_OUT_CERT_TYPE = 'MOVE_OUT';
export const TERMINATION_CERT_TYPE = 'TERMINATION';
export const INTERIM_CERT_TYPE = 'INTERIM';
export const INTERIM_UT_CERT_TYPE = 'INTERIM_UT';
export const EVICTION_CERT_TYPE = 'EVICTION';
export const CO_TENANT_TO_TENANT_CERT_TYPE = 'CO_TENANT_TO_TENANT';
export const INITIATE_ABSENCE_CERT_TYPE = 'INITIATE_ABSENCE';
export const TERMINATE_ABSENCE_CERT_TYPE = 'TERMINATE_ABSENCE';
export const ASSIGN_REMOVE_RA_CERT_TYPE = 'ASSIGN_REMOVE_RA';
export const GROSS_RENT_CERT_TYPE = 'GROSS_RENT';
export const RD_GROSS_RENT_CERT_TYPE = 'RD_GROSS_RENT';
export const VACATE_CERT_TYPE = 'VACATE';
export const MODIFY_CERT_TYPE = 'MODIFY';
export const UNIT_TRANSFER_CERT_TYPE = 'UNIT_TRANSFER';

export const OTHER_HUD = 'OTHER_HUD';
export const OTHER_LIHTC = 'OTHER_LIHTC';
export const OTHER = 'OTHER';

export const CERTIFICATION_TYPE_DISPLAY_NAMES = {
  [RECERT_CERT_TYPE]: 'Recert',
  [RECERT_UT_CERT_TYPE]: 'Recert (Transfer)',
  [GROSS_RENT_CERT_TYPE]: 'Gross-Rent',
  [MOVE_IN_CERT_TYPE]: 'Move-in',
  [MOVE_OUT_CERT_TYPE]: 'Move-Out',
  [INITIAL_CERT_TYPE]: 'Initial',
  [INITIAL_UT_CERT_TYPE]: 'Initial (Transfer)',
  [INTERIM_CERT_TYPE]: 'Interim',
  [INTERIM_UT_CERT_TYPE]: 'Interim (Transfer)',
  [OTHER_LIHTC]: 'Other LIHTC',
  [OTHER_HUD]: 'Other HUD',
  [TERMINATION_CERT_TYPE]: 'Termination',
  [EVICTION_CERT_TYPE]: 'Eviction',
  [CO_TENANT_TO_TENANT_CERT_TYPE]: 'Co-Tenant to Tenant',
  [INITIATE_ABSENCE_CERT_TYPE]: 'Initiate Absence',
  [TERMINATE_ABSENCE_CERT_TYPE]: 'Terminate Absence',
  [ASSIGN_REMOVE_RA_CERT_TYPE]: 'Assign / Remove RA',
  [VACATE_CERT_TYPE]: 'Vacate',
  [MODIFY_CERT_TYPE]: 'Modify Certification',
  [UNIT_TRANSFER_CERT_TYPE]: 'Unit Transfer',
};

export const CERTIFICATION_TYPE_LOWERCASE_KEYS = {
  [RECERT_CERT_TYPE]: 'recert',
  [RECERT_UT_CERT_TYPE]: 'recertUt',
  [GROSS_RENT_CERT_TYPE]: 'gross-rent',
  [MOVE_IN_CERT_TYPE]: 'move-in',
  [MOVE_OUT_CERT_TYPE]: 'move-Out',
  [INITIAL_CERT_TYPE]: 'initial',
  [INITIAL_UT_CERT_TYPE]: 'initialUt',
  [INTERIM_CERT_TYPE]: 'interim',
  [INTERIM_UT_CERT_TYPE]: 'interimUt',
  [OTHER_LIHTC]: 'other-lihtc',
  [OTHER_HUD]: 'other-Hud',
  [OTHER]: 'other',
  [TERMINATION_CERT_TYPE]: 'termination',
  [EVICTION_CERT_TYPE]: 'eviction',
  [CO_TENANT_TO_TENANT_CERT_TYPE]: 'co-tenant-to-tenant',
  [INITIATE_ABSENCE_CERT_TYPE]: 'initiate-absence',
  [TERMINATE_ABSENCE_CERT_TYPE]: 'terminate-absence',
  [ASSIGN_REMOVE_RA_CERT_TYPE]: 'assign-remove-ra',
  [VACATE_CERT_TYPE]: 'vacate',
  [MODIFY_CERT_TYPE]: 'modify',
  [UNIT_TRANSFER_CERT_TYPE]: 'unit-transfer',
};

export const CERTIFICATION_TYPE_DISPLAY_NAMES_LOWERCASE_KEY_MAP = {
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[RECERT_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[RECERT_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[RECERT_UT_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[RECERT_UT_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[GROSS_RENT_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[GROSS_RENT_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[MOVE_IN_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[MOVE_IN_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[MOVE_OUT_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[MOVE_OUT_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[INITIAL_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[INITIAL_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[INITIAL_UT_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[INITIAL_UT_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[INTERIM_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[INTERIM_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[INTERIM_UT_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[INTERIM_UT_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[OTHER_LIHTC]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[OTHER_LIHTC],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[OTHER_HUD]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[OTHER_HUD],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[OTHER]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[OTHER],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[TERMINATION_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[TERMINATION_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[EVICTION_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[EVICTION_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[CO_TENANT_TO_TENANT_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[CO_TENANT_TO_TENANT_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[INITIATE_ABSENCE_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[INITIATE_ABSENCE_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[TERMINATE_ABSENCE_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[TERMINATE_ABSENCE_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[ASSIGN_REMOVE_RA_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[ASSIGN_REMOVE_RA_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[VACATE_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[VACATE_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[MODIFY_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[MODIFY_CERT_TYPE],
  [CERTIFICATION_TYPE_LOWERCASE_KEYS[UNIT_TRANSFER_CERT_TYPE]]:
    CERTIFICATION_TYPE_DISPLAY_NAMES[UNIT_TRANSFER_CERT_TYPE],
};
export const certificationTypeMessages = defineMessages({
  initial: {
    id: 'App.AffordableCertificationTypes.Initial',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[INITIAL_CERT_TYPE],
  },
  initial_ut: {
    id: 'App.AffordableCertificationTypes.Initial_UT',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[INITIAL_UT_CERT_TYPE],
  },
  interim: {
    id: 'App.AffordableCertificationTypes.Interim',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[INTERIM_CERT_TYPE],
  },
  interim_ut: {
    id: 'App.AffordableCertificationTypes.Interim_UT',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[INTERIM_UT_CERT_TYPE],
  },
  modify: {
    id: 'App.AffordableCertificationTypes.Modify',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[MODIFY_CERT_TYPE],
  },
  moveIn: {
    id: 'App.AffordableCertificationTypes.MoveIn',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[MOVE_IN_CERT_TYPE],
  },
  moveOut: {
    id: 'App.AffordableCertificationTypes.MoveOut',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[MOVE_OUT_CERT_TYPE],
  },
  recertification: {
    id: 'App.AffordableCertificationTypes.Recertification',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[RECERT_CERT_TYPE],
  },
  recertification_ut: {
    id: 'App.AffordableCertificationTypes.Recertification_UT',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[RECERT_UT_CERT_TYPE],
  },
  termination: {
    id: 'App.AffordableCertificationTypes.Termination',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[TERMINATION_CERT_TYPE],
  },
  eviction: {
    id: 'App.AffordableCertificationTypes.Eviction',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[EVICTION_CERT_TYPE],
  },
  coTenantToTenant: {
    id: 'App.AffordableCertificationTypes.CoTenantToTenant',
    defaultMessage:
      CERTIFICATION_TYPE_DISPLAY_NAMES[CO_TENANT_TO_TENANT_CERT_TYPE],
  },
  initiateAbsence: {
    id: 'App.AffordableCertificationTypes.InitiateAbsence',
    defaultMessage:
      CERTIFICATION_TYPE_DISPLAY_NAMES[INITIATE_ABSENCE_CERT_TYPE],
  },
  terminateAbsence: {
    id: 'App.AffordableCertificationTypes.TerminateAbsence',
    defaultMessage:
      CERTIFICATION_TYPE_DISPLAY_NAMES[TERMINATE_ABSENCE_CERT_TYPE],
  },
  assignRemoveRA: {
    id: 'App.AffordableCertificationTypes.AssignRemoveRA',
    defaultMessage:
      CERTIFICATION_TYPE_DISPLAY_NAMES[ASSIGN_REMOVE_RA_CERT_TYPE],
  },
  grossRent: {
    id: 'App.AffordableCertificationTypes.GrossRent',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[GROSS_RENT_CERT_TYPE],
  },
  vacate: {
    id: 'App.AffordableCertificationTypes.Vacate',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[VACATE_CERT_TYPE],
  },
  unitTransfer: {
    id: 'App.AffordableCertificationTypes.UnitTransfer',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[UNIT_TRANSFER_CERT_TYPE],
  },
});

export const certificationTypeMessagesDisplayNameMap = defineMessages({
  [CERTIFICATION_TYPE_DISPLAY_NAMES[INITIAL_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.Initial',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[INITIAL_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[INITIAL_UT_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.Initial_UT',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[INITIAL_UT_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[INTERIM_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.Interim',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[INTERIM_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[INTERIM_UT_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.Interim_UT',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[INTERIM_UT_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[MODIFY_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.Modify',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[MODIFY_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[MOVE_IN_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.MoveIn',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[MOVE_IN_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[MOVE_OUT_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.MoveOut',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[MOVE_OUT_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[RECERT_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.Recertification',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[RECERT_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[RECERT_UT_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.Recertification_UT',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[RECERT_UT_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[TERMINATION_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.Termination',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[TERMINATION_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[EVICTION_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.Eviction',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[EVICTION_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[CO_TENANT_TO_TENANT_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.CoTenantToTenant',
    defaultMessage:
      CERTIFICATION_TYPE_DISPLAY_NAMES[CO_TENANT_TO_TENANT_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[INITIATE_ABSENCE_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.InitiateAbsence',
    defaultMessage:
      CERTIFICATION_TYPE_DISPLAY_NAMES[INITIATE_ABSENCE_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[TERMINATE_ABSENCE_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.TerminateAbsence',
    defaultMessage:
      CERTIFICATION_TYPE_DISPLAY_NAMES[TERMINATE_ABSENCE_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[ASSIGN_REMOVE_RA_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.AssignRemoveRA',
    defaultMessage:
      CERTIFICATION_TYPE_DISPLAY_NAMES[ASSIGN_REMOVE_RA_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[GROSS_RENT_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.GrossRent',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[GROSS_RENT_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[VACATE_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.Vacate',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[VACATE_CERT_TYPE],
  },
  [CERTIFICATION_TYPE_DISPLAY_NAMES[UNIT_TRANSFER_CERT_TYPE]]: {
    id: 'App.AffordableCertificationTypes.UnitTransfer',
    defaultMessage: CERTIFICATION_TYPE_DISPLAY_NAMES[UNIT_TRANSFER_CERT_TYPE],
  },
});
