import { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { RenewCircleIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';

import RDLimitForm from './RDLimitForm';
import Notes from './Notes';
import { mapperFloorPlans, getMessageSuccess } from './utils';

// Hooks
import { useFloorPlansQuery, useAsyncCreateFloorPlansRdLimits } from './hooks';

// Others
import messages from './messages';

const RDLimitsModal = ({
  organizationId,
  propertyId,
  intl,
  open,
  onClose,
  selectedProperty,
  refetchFloorPlans,
}) => {
  const { setup } = selectedProperty || {};
  const { rdProjectType } = setup || {};
  const { rdrents } = rdProjectType || {};
  const { noteRent, basicRent } = rdrents || {};
  const noteRentOnly = noteRent && !basicRent;

  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const formValuesRef = useRef();
  const isValidFormsRef = useRef();
  const isDirtyFormsRef = useRef();
  const messageSuccess = useRef();

  const { isLoading, floorPlans, refetch } = useFloorPlansQuery(
    organizationId,
    propertyId,
    open,
  );

  const {
    mutate: createRdLimitsMutate,
    isLoading: createRdLimitsMutateIsLoading,
  } = useAsyncCreateFloorPlansRdLimits({
    organizationId,
    propertyId,
    options: {
      onSuccess: () => {
        toastr.success('Success', messageSuccess.current);
        onClose();
        refetch();
        refetchFloorPlans();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  // Note: Handle here the form values
  const handleFormChange = (updatedValues) => {
    formValuesRef.current = {
      ...formValuesRef.current,
      [updatedValues.id]: updatedValues,
    };
  };

  const isEmptyValue = (value) =>
    value === undefined || value === null || value === '';

  const validateEmptyValues = useCallback(() => {
    if (formValuesRef.current) {
      const allEmpty = Object.values(formValuesRef?.current).every(
        (floorplan) => {
          return [
            floorplan.newNoteRent,
            floorplan.newRdUtilityAllowance,
            floorplan.newBasicRent,
            floorplan.startDate,
          ].every(isEmptyValue);
        },
      );
      return allEmpty;
    }
    return false;
  }, [formValuesRef]);

  const emptyValues = validateEmptyValues();
  const isValidEmpty = isValid && isDirty && emptyValues;

  const { modalProps } = useMemo(() => {
    const props = {
      modalProps: {},
    };
    props.modalProps = {
      title: <FormattedMessage {...messages.title} />,
      icon: <RenewCircleIcon />,
      ModalProps: {
        PaperProps: {
          sx: {
            maxWidth: '80vw',
          },
        },
      },
      ContentProps: {
        sx: {
          width: 1500,
        },
      },
      TitleProps: {
        style: {
          alignItems: 'center',
        },
      },
      actionsProps: [
        {
          children: 'Cancel',
          onClick: onClose,
        },
        {
          children: 'Save',
          submitButton: true,
          disabled: !isValid || !isDirty || isValidEmpty,
          isSubmitting: createRdLimitsMutateIsLoading,
          onClick: () => {
            const valuesForm = formValuesRef.current;
            const mapped = mapperFloorPlans(valuesForm);
            messageSuccess.current = getMessageSuccess(mapped, intl);
            createRdLimitsMutate({ payload: mapped });
          },
        },
      ],
    };

    return props;
  }, [
    onClose,
    isDirty,
    isValid,
    isValidEmpty,
    createRdLimitsMutate,
    createRdLimitsMutateIsLoading,
    intl,
  ]);

  const handleIsValid = (floorPlanId, isValid) => {
    isValidFormsRef.current = {
      ...isValidFormsRef.current,
      [floorPlanId]: isValid,
    };
    const allValid = Object.values(isValidFormsRef.current).every(
      (val) => val === true,
    );
    setIsValid(allValid);
  };

  const handleIsDirty = (floorPlanId, isDirty) => {
    isDirtyFormsRef.current = {
      ...isDirtyFormsRef.current,
      [floorPlanId]: isDirty,
    };
    const someDirty = Object.values(isDirtyFormsRef.current).some(
      (val) => val === true,
    );
    setIsDirty(someDirty);
  };

  return (
    <>
      <Modal open={open} onClose={onClose} {...modalProps}>
        <AsyncBox loading={isLoading} sx={{ marginTop: '26px' }}>
          <>
            {floorPlans.map((floorPlan, index) => (
              <RDLimitForm
                key={floorPlan.id}
                floorPlan={floorPlan}
                intl={intl}
                withHeader={index === 0}
                noteRentOnly={noteRentOnly}
                handleIsDirty={handleIsDirty}
                handleIsValid={handleIsValid}
                onChange={handleFormChange}
              />
            ))}
            <Notes />
          </>
        </AsyncBox>
      </Modal>
    </>
  );
};

export default RDLimitsModal;
