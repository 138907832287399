import { forEachObjIndexed, omit } from 'ramda';
import moment from 'moment';

import type { OrderValue, WorkOrder } from '../containers/App/types';

const {
  get,
  apiURL,
  post,
  requestToDownload,
  del,
  getRequestOptions,
} = require('../utils/api');

const request = require('../utils/api').default;

const encodeParams = (p) => {
  return Object.entries(p)
    .map((kv) =>
      kv
        .map(encodeURIComponent)
        .filter((valid) => valid !== 'undefined')
        .join('='),
    )
    .join('&');
};

type getAllWorkordersParams = {
  householdId: string,
  customerId: string,
  unitId: string,
  pageNumber: number,
  limit?: number,
  order?: OrderValue,
  sort?: string,
};
class WorkOrderService {
  save(workOrder: Object, organizationId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };
    return post(
      `/${organizationId}/${propertyId}/work-orders`,
      workOrder,
      options,
    );
  }
  update(workOrder: WorkOrder, organizationId: string) {
    const { detailId, propertyId } = workOrder;

    if (workOrder.serviceLogs) {
      workOrder.serviceLogs = workOrder.serviceLogs
        .filter((serviceLog) => {
          const { serviceDate, startTime, endTime } = serviceLog;
          const validServiceDate = serviceDate && moment(serviceDate).isValid();
          const validStartTime = startTime && moment(startTime).isValid();
          const validEndTime =
            endTime &&
            moment(endTime).isValid() &&
            moment(endTime).isAfter(startTime);

          return validServiceDate && validStartTime && validEndTime;
        })
        .map((serviceLog) => {
          const { serviceDate, startTime, endTime } = serviceLog;
          return {
            serviceDate: moment(serviceDate).format('YYYY-MM-DD'),
            startTime: moment(startTime).format('h:mm A'),
            endTime: moment(endTime).format('h:mm A'),
          };
        });
    }

    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(workOrder),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/work-orders/${detailId}`,
      options,
    );
  }
  changeStatus(
    workOrder: WorkOrder,
    workOrderStatusId: string,
    notes: string,
    organizationId: string,
  ) {
    const { detailId, propertyId } = workOrder;
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        workOrderStatusId,
        notes,
      }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/workOrder/${detailId}/status`,
      options,
    );
  }

  getPropertyWorkOrders({
    propertyId,
    organizationId,
    defaultFilterActive = true,
  }) {
    return get(
      `/${organizationId}/${propertyId}/work-orders?pagination=false${
        defaultFilterActive ? '&defaultFilterActive=true' : ''
      }`,
    );
  }

  getAllWorkOrders(
    pageNumber: number,
    limit: number,
    filterValue: any,
    sorting: OrderValue,
    searchText: string,
    organizationId: string,
    propertyId: string,
    defaultFilterActive?: boolean,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const filters: Array<string> = [];
    const filterValues = omit(['dateFrom', 'dateTo'], filterValue);
    forEachObjIndexed((valueFilter, keyFilter) => {
      forEachObjIndexed((valueOption, keyOption) => {
        if (valueOption) {
          filters.push(`${keyFilter}=${keyOption}`);
        }
      }, valueFilter);
    }, filterValues);
    if (filterValue.dateFrom) {
      const dateFrom = moment(filterValue.dateFrom).toISOString();
      filters.push(`dateFrom=${dateFrom}`);
    }
    if (filterValue.dateTo) {
      const dateTo = moment(filterValue.dateTo).toISOString();
      filters.push(`dateTo=${dateTo}`);
    }
    if (defaultFilterActive) {
      // $FlowFixMe
      filters.push(`defaultFilterActive=${defaultFilterActive}`);
    }
    const sort =
      sorting.fieldName === ''
        ? ''
        : `&sort=${sorting.fieldName}&order=${sorting.order}`;
    const query = searchText === '' ? '' : `&query=${searchText}`;
    const pageNumberString = !!pageNumber ? `page=${pageNumber}&` : '';
    const queryString = `${pageNumberString}limit=${limit}${query}${sort}&${filters.join(
      '&',
    )}`;
    return request(
      `${apiURL}/${organizationId}/${propertyId}/work-orders?${queryString}`,
      options,
    );
  }

  getWorkOrdersByOrganization({ options }) {
    return request(`${apiURL}/all-work-orders`, {
      ...options,
      ...getRequestOptions(),
    });
  }
  getOne(organizationId: string, propertyId: string, workOrderId: string) {
    const id = workOrderId;
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/work-orders/${id}`,
      options,
    );
  }

  getAllWorkOrderUnitIssues(organizationId: string, propertyId: string) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/work-orders/unit-issues`,
      options,
    );
  }

  printWorkOrder(
    organizationId: string,
    propertyId: string,
    workOrderId: string,
    offset: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };
    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/work-orders/${workOrderId}/generate?offset=${offset}`,
      options,
    );
  }

  getAllWorkOrdersByHousehold(params: getAllWorkordersParams) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(`${apiURL}/work-orders?${encodeParams(params)}`, options);
  }
  uploadPhoto(
    workOrderId: Object,
    organizationId: string,
    propertyId: string,
    type: string,
    image: any,
  ) {
    const body = new FormData();
    body.append('image', image);
    body.append('type', type);

    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };
    return post(
      `/${organizationId}/${propertyId}/work-order-photo/${workOrderId}`,
      body,
      options,
    );
  }
  async deletePhoto(
    imageId: Object,
    organizationId: string,
    propertyId: string,
  ) {
    return del(`/${organizationId}/${propertyId}/work-order-photo/${imageId}`);
  }
}

export default WorkOrderService;
