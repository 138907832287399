import { get, post } from '../utils/api';

export default class RentForecastService {
  async createRentForecast(organizationId, propertyId, options) {
    return await post(
      `/${organizationId}/${propertyId}/rent-forecast`,
      options,
    );
  }
  async getAllRentForecast(organizationId, propertyId) {
    return await get(`/${organizationId}/${propertyId}/rent-forecast`);
  }
}
