import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from '../messages';

const GenerateReportButton = ({
  organizationId,
  propertyId,
  disabled,
  intl,
}) => {
  return (
    <Button
      variant={'shout'}
      disabled={disabled}
      intl={intl}
      onClick={() => {}}
    >
      <FormattedMessage {...messages.generateReport} />
    </Button>
  );
};

export default GenerateReportButton;
