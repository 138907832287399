import { useMemo, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';

// Components
import {
  Modal,
  ModalConfirm,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { PlusIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

// Hooks
import useReactHookFormProps from '../../../hooks/useReactHookFormProps';
import { useCreateTransactionTest } from '../hooks.mincTransactionsMutations';

// Others
import messages from '../messages';

const confirmModalPropsDiscardChanges = ({
  closeConfirmModal,
  handleClose,
  openConfirm,
}) => ({
  title: 'Discard Changes?',
  onClose: closeConfirmModal,
  open: openConfirm,
  actionsProps: [
    {
      children: 'Cancel',
      onClick: closeConfirmModal,
    },
    {
      children: 'Discard Changes',
      onClick: () => {
        handleClose();
        closeConfirmModal();
      },
    },
  ],
});

/**
 * This component will be used later on for the modals managed in the ManageMincTransactions screen.
 * -
 * @param {*} param0
 * @returns
 */
const ManageMincTransactionsModals = ({
  organizationId,
  propertyId,
  refetchTransactions,
  intl,
  modalState,
  modalType,
  open,
  openConfirm,
  openConfirmModal,
  handleClose,
  closeConfirmModal,
}) => {
  const {
    mutate: createTransactionTestMutate,
    isLoading: createTransactionTestMutationIsLoading,
  } = useCreateTransactionTest({
    organizationId,
    propertyId,
    options: {
      onSuccess: (res) => {
        toastr.success('Success', res.message);
        handleClose();
        refetchTransactions();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const ReactHookFormProps = useReactHookFormProps({});
  const { reset, getValues, isValid, isDirty } = ReactHookFormProps;

  useEffect(() => {
    const { testField } = modalState?.transaction || {};
    const defaultValues = {
      createTransactionTest: {
        testField: testField || '',
      },
    }[modalType];

    reset(defaultValues);
  }, [reset, modalType, modalState]);

  /**
   * Possible form fields for later
   * -
   * @param {*} param0
   * @returns
   */
  const formFields = useCallback(({ intl }) => {
    let disabled = false;
    return [
      {
        variant: 'text',
        name: 'testField',
        label: <FormattedMessage {...messages.testField} />,
        rules: {
          required: true,
        },
        disabled,
        GridProps: {
          xs: 6,
        },
      },
    ];
  }, []);

  const { confirmModalProps, modalProps, formProps } = useMemo(() => {
    const props = {
      modalProps: {},
      formProps: {},
      confirmModalProps: {},
    };

    switch (modalType) {
      case 'createTransactionTest':
        props.modalProps = {
          title: <FormattedMessage {...messages.modalTitle} />,
          icon: <PlusIcon />,
          actionsProps: [
            {
              children: 'Cancel',
              onClick: isDirty ? openConfirmModal : handleClose,
            },
            {
              children: 'Save',
              submitButton: true,
              isSubmitting: createTransactionTestMutationIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  testField: values.testField,
                };
                createTransactionTestMutate({ payload });
              },
            },
          ],
        };
        props.formProps = {
          fields: formFields({ intl }),
        };
        props.confirmModalProps = confirmModalPropsDiscardChanges({
          closeConfirmModal,
          handleClose,
          openConfirm,
        });

        break;
      default:
        break;
    }

    return props;
  }, [
    // mutations
    createTransactionTestMutate,
    createTransactionTestMutationIsLoading,
    // form
    getValues,
    isDirty,
    isValid,
    formFields,
    // modal
    handleClose,
    closeConfirmModal,
    modalState,
    modalType,
    openConfirm,
    openConfirmModal,
    intl,
  ]);

  return (
    <>
      <Modal open={open} onClose={handleClose} {...modalProps}>
        <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
      </Modal>
      <ModalConfirm open={openConfirm} {...confirmModalProps} />
    </>
  );
};

export default ManageMincTransactionsModals;
