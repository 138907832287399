import { useMemo } from 'react';

import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import { HEADERS } from './constants';
import { CERTIFICATION_TYPE_DISPLAY_NAMES } from '../../constants/affordableCertificationTypes';

import { processTableFilterOptions } from '../../utils/table-helpers';

const useBuildFilterOptions = ({ results }) => {
  return useMemo(() => {
    const options = {
      certificationType: [],
    };
    if (results) {
      results.forEach((result) => {
        if (result.certificationType) {
          options.certificationType.push(
            CERTIFICATION_TYPE_DISPLAY_NAMES[result.certificationType] ??
              result.certificationType,
          );
        }
      });
    }

    // Remove duplicates, sort, and map to label/value objects
    processTableFilterOptions(options);

    return options;
  }, [results]);
};

const useHeaders = ({
  results,
  checkBoxListProps: { handleSelectDeSelectAllRows },
}) => {
  // Add filters if needed later
  const filterOptions = useBuildFilterOptions({ results });
  return useMemo(() => {
    const headers = [];

    // Add headers
    HEADERS.forEach((header) => {
      headers.push({
        ...header,
        filterOptions: header.filterOptions ?? filterOptions[header.id],
      });
    });

    const checkboxHeader = {
      id: 'checkbox',
      label: (
        <Checkbox
          onChange={(e) => handleSelectDeSelectAllRows(e)}
          color="secondary"
          sx={{
            color: 'white',
          }}
        />
      ),
    };

    headers.unshift(checkboxHeader);

    return headers;
  }, [filterOptions, handleSelectDeSelectAllRows]);
};

export default useHeaders;
