import React from 'react';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';
import { Input } from '@fortress-technology-solutions/fortress-component-library/Atoms';

const CashFlowTable = ({ data, headers, tableState, setTableState }) => {
  return (
    <Box>
      <Box display="flex">
        {headers.map((header, headerIndex) => (
          <Box
            key={headerIndex}
            flex={header.flex}
            textAlign={header.textAlign || 'right'}
            padding="8px 16px"
          >
            <Typography variant="labelLarge">{header.label}</Typography>
          </Box>
        ))}
      </Box>
      {data.map((section, sectionIndex) => (
        <Box key={sectionIndex} mb={4}>
          <Box
            display="flex"
            padding="8px 16px"
            sx={{
              backgroundColor: palette.lighter.lightGrey,
              borderTop: `1px solid ${palette.lighter.grey}`,
            }}
          >
            <Typography variant="labelLarge">{section.header}</Typography>
            {section?.headerSuffix && (
              <Typography variant="labelLarge" color="textSecondary">
                {' '}
                {section?.headerSuffix}
              </Typography>
            )}
          </Box>

          {section.rows.map((row, rowIndex) => (
            <Box
              key={rowIndex}
              display="flex"
              alignItems="center"
              sx={{
                borderBottom: `1px solid ${palette.light.lightGreyAlt}`,
              }}
            >
              {headers.map((header, headerIndex) => {
                const page = tableState?.page;
                const key = `${page}-${sectionIndex}-${rowIndex}-${header.key}`;
                const value = tableState[key] || row[header.key];
                const suffixValue = row[`${header.key}Suffix`];

                return (
                  <Box
                    key={headerIndex}
                    flex={header.flex || 1}
                    textAlign={header.textAlign || 'right'}
                    padding="8px 16px"
                  >
                    {header.editable ? (
                      <Input
                        value={value || ''}
                        onChange={(e) =>
                          setTableState({
                            ...tableState,
                            [key]: e.target.value,
                          })
                        }
                        placeholder={header.placeholder || ''}
                        gutterBottom={false}
                        HelperTextProps={{
                          sx: { display: 'none' },
                        }}
                      />
                    ) : (
                      <>
                        <Typography variant="bodySmall">
                          {value || '—'}
                        </Typography>
                        {suffixValue && (
                          <Typography variant="bodySmall" color="textSecondary">
                            {' '}
                            {suffixValue}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default CashFlowTable;
