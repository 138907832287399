import { defineMessages } from 'react-intl';

const messages = defineMessages({
  invalidName: {
    id: 'App.WorkOrder.WorkOrderForm.InvalidName',
    defaultMessage: 'Names must have letters or digits only',
  },
  invalidEmailAddress: {
    id: 'App.WorkOrder.WorkOrderForm.InvalidEmailAddress',
    defaultMessage: 'Invalid email address',
  },
  invalidPhoneNumber: {
    id: 'App.WorkOrder.WorkOrderForm.invalidPhoneNumber',
    defaultMessage: 'Invalid phone number',
  },
  requiredField: {
    id: 'App.WorkOrder.WorkOrderForm.RequiredFields',
    defaultMessage: 'This is a required field',
  },
  invalidDateFormat: {
    id: 'App.WorkOrder.WorkOrderForm.InvalidDateFormat',
    defaultMessage: 'Invalid date format.',
  },
  invalidTimeFormat: {
    id: 'App.WorkOrder.WorkOrderForm.InvalidTimeFormat',
    defaultMessage: 'Invalid time format.',
  },
  invalidReportedDateValue: {
    id: 'App.WorkOrder.WorkOrderForm.InvalidReportedDateValue',
    defaultMessage: 'Value must be today or earlier.',
  },
  invalidDateFromPreferenceValue: {
    id: 'App.WorkOrder.WorkOrderForm.InvalidDateFromPreferenceValue',
    defaultMessage: 'Value must be today or later.',
  },
  invalidFinishDateValue: {
    id: 'App.WorkOrder.WorkOrderForm.InvalidFinishDateValue',
    defaultMessage: 'Value must be provided to be able to complete.',
  },
  invalidDateToPreferenceValue: {
    id: 'App.WorkOrder.WorkOrderForm.InvalidDateToPreferenceValue',
    defaultMessage: 'Value must be after first date.',
  },
  invalidTimeToPreferenceValue: {
    id: 'App.WorkOrder.WorkOrderForm.InvalidTimeToPreferenceValue',
    defaultMessage: 'Value must be after first time.',
  },
  cancel: {
    id: 'App.WorkOrder.WorkOrderForm.Cancel',
    defaultMessage: 'Cancel',
  },
  deleteConfirmation: {
    id: 'App.WorkOrder.WorkOrderForm.DeleteConfirmation',
    defaultMessage:
      'Are you sure you want to discard the information on the form?',
  },
  create: {
    id: 'App.WorkOrder.WorkOrderForm.Create',
    defaultMessage: 'Create',
  },
  createAndPrint: {
    id: 'App.WorkOrder.WorkOrderForm.CreateAndPrint',
    defaultMessage: 'Create & Print',
  },
  nonexistentUnit: {
    id: 'App.WorkOrder.WorkOrderForm.NonexistentUnit',
    defaultMessage: 'Must input an existing unit',
  },
  save: {
    id: 'App.WorkOrder.WorkOrderForm.Save',
    defaultMessage: 'Save',
  },
  saveAndPrint: {
    id: 'App.WorkOrder.WorkOrderForm.EditAndPrint',
    defaultMessage: 'Save & Print',
  },
  print: {
    id: 'App.WorkOrder.WorkOrderForm.Print',
    defaultMessage: 'Print',
  },
  createWorkOrder: {
    id: 'App.WorkOrder.WorkOrderForm.CreateWorkOrder',
    defaultMessage: 'Create Work Order',
  },
  required: {
    id: 'App.WorkOrder.WorkOrderForm.Required',
    defaultMessage: '(*) Required Fields',
  },
  workOrderId: {
    id: 'App.WorkOrder.WorkOrderForm.WorkOrderId',
    defaultMessage: 'Work Order ID',
  },
  createdAt: {
    id: 'App.WorkOrder.WorkOrderForm.CreatedAt',
    defaultMessage: 'Created at',
  },
  daysOpen: {
    id: 'App.WorkOrder.WorkOrderForm.DaysOpen',
    defaultMessage: 'Days Open',
  },
  edit: {
    id: 'App.WorkOrder.WorkOrderForm.Edit',
    defaultMessage: 'Edit Work Order',
  },
  maintenanceRequest: {
    id: 'App.WorkOrder.WorkOrderForm.maintenanceRequest',
    defaultMessage: 'Maintenance Request',
  },
  vendorNotes: {
    id: 'App.WorkOrder.WorkOrderForm.VendorNotes',
    defaultMessage: 'Third Party Vendor Notes',
  },
  issueComment: {
    id: 'App.WorkOrder.WorkOrderForm.IssueComment',
    defaultMessage: 'Issue Comment',
  },
  entryInstructions: {
    id: 'App.WorkOrder.WorkOrderForm.EntryInstructions',
    defaultMessage: 'Entry Instructions',
  },
  noteToTechnician: {
    id: 'App.WorkOrder.WorkOrderForm.NoteToTechnician',
    defaultMessage: 'Note to Technician',
  },
  afterServiceNote: {
    id: 'App.WorkOrder.WorkOrderForm.AfterServiceNote',
    defaultMessage: 'After Service Note from Tech',
  },
  tooLong: {
    id: 'App.WorkOrder.WorkOrderForm.tooLong',
    defaultMessage: '500 character max limit',
  },
  endTimeBeforeStartTime: {
    id: 'App.WorkOrder.WorkOrderForm.EndTimeBeforeStartTime',
    defaultMessage: 'End time must be after start time',
  },
  requiredHelperText: {
    id: 'App.WorkOrder.WorkOrderForm.RequiredHelperText',
    defaultMessage: 'Required',
  },
  totalDuration: {
    id: 'App.WorkOrder.WorkOrderForm.TotalDuration',
    defaultMessage: 'Total Duration',
  },
  totalAmountOwedByResidentError: {
    id: 'App.WorkOrder.WorkOrderForm.TotalAmountOwedByResidentError',
    defaultMessage:
      'Total amount owed by resident cannot be greater than total cost of materials used',
  },
});

export default messages;
