export const GET_ALL_FLOORPLAN_PRICING =
  'app/VIEW_UNIT/EDIT_FEES/GET_ALL_FLOORPLAN_PRICING';
export const GET_ALL_FLOORPLAN_PRICING_FAILURE =
  'app/VIEW_UNIT/EDIT_FEES/GET_ALL_FLOORPLAN_PRICING_FAILURE';
export const GET_ALL_FLOORPLAN_PRICING_SUCCESS =
  'app/VIEW_UNIT/EDIT_FEES/GET_ALL_FLOORPLAN_PRICING_SUCCESS';
export const CREATE_FLOORPLAN_PRICING =
  'app/VIEW_UNIT/EDIT_FEES/CREATE_FLOORPLAN_PRICING';
export const CREATE_FLOORPLAN_PRICING_FAILURE =
  'app/VIEW_UNIT/EDIT_FEES/CREATE_FLOORPLAN_PRICING_FAILURE';
export const CREATE_FLOORPLAN_PRICING_SUCCESS =
  'app/VIEW_UNIT/EDIT_FEES/CREATE_FLOORPLAN_PRICING_SUCCESS';
export const UPDATE_FLOORPLAN_PRICING =
  'app/VIEW_UNIT/EDIT_FEES/UPDATE_FLOORPLAN_PRICING';
export const UPDATE_FLOORPLAN_PRICING_FAILURE =
  'app/VIEW_UNIT/EDIT_FEES/UPDATE_FLOORPLAN_PRICING_FAILURE';
export const UPDATE_FLOORPLAN_PRICING_SUCCESS =
  'app/VIEW_UNIT/EDIT_FEES/UPDATE_FLOORPLAN_PRICING_SUCCESS';
export const DELETE_FLOORPLAN_PRICING =
  'app/VIEW_UNIT/EDIT_FEES/DELETE_FLOORPLAN_PRICING';
export const DELETE_FLOORPLAN_PRICING_FAILURE =
  'app/VIEW_UNIT/EDIT_FEES/DELETE_FLOORPLAN_PRICING_FAILURE';
export const DELETE_FLOORPLAN_PRICING_SUCCESS =
  'app/VIEW_UNIT/EDIT_FEES/DELETE_FLOORPLAN_PRICING_SUCCESS';

export const FEE_MODES = {
  MARKET_RENT: 'MARKET_RENT',
  QUOTING_RENT: 'QUOTING_RENT',
  DEPOSIT: 'DEPOSIT',
  GROSS_RENT: 'GROSS_RENT',
  FINANCIAL_MARKET_RENT: 'FINANCIAL_MARKET_RENT',
  UTILITY_ALLOWANCE: 'UTILITY_ALLOWANCE',
  RD_UTILITY_ALLOWANCE: 'RD_UTILITY_ALLOWANCE',
  HUD_GROSS_RENT: 'HUD_GROSS_RENT',
  NOTE_RENT: 'NOTE_RENT',
  BASIC_RENT: 'BASIC_RENT',
  NON_OPTIONAL_CHARGE: 'NON_OPTIONAL_CHARGE',
  '236_BASIC_RENT': '236_BASIC_RENT',
  '236_MARKET_RENT': '236_MARKET_RENT',
};

export const FORM_MODES = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const FEE_MODE_MAP = {
  MARKET_RENT: 'marketRents',
  QUOTING_RENT: 'quotingRents',
  DEPOSIT: 'deposits',
  FINANCIAL_MARKET_RENT: 'financialMarketRents',
  HUD_GROSS_RENT: 'HUDGrossRents',
  NOTE_RENT: 'noteRents',
  BASIC_RENT: 'basicRents',
  NON_OPTIONAL_CHARGE: 'nonOptionalCharges',
  '236_BASIC_RENT': 'hud236BasicRents',
  '236_MARKET_RENT': 'hud236MarketRents',
};
