import React from 'react';

import messages from '../messages';
import { PlusIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { TableHeader } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useIntl from '../../../hooks/useIntl';

const RentForecastHeader = (props) => {
  const { count, openRentForecastModal, openRDBudgetModal, totalCount } = props;
  const intl = useIntl();

  return (
    <TableHeader
      title={intl.formatMessage(messages.title)}
      actions={[
        <Button
          onClick={openRentForecastModal}
          variant="shout"
          startIcon={<PlusIcon />}
        >
          {intl.formatMessage(messages.createRentForecast)}
        </Button>,
        <Button onClick={openRDBudgetModal} disabled={!totalCount}>
          {intl.formatMessage(messages.reviewRDBudget)}
        </Button>,
      ]}
      count={count}
      totalCount={totalCount}
    />
  );
};

export default RentForecastHeader;
