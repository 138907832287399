import { useMemo } from 'react';

import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Atoms';

// TODO: need to figure out how to make the checkboxes rerender when filters and sorting are applied
const useRows = ({
  mincTransactions,
  checkBoxListProps: { checkboxList, handleSelectRow },
}) => {
  return useMemo(() => {
    return mincTransactions.map((row, index) => {
      const headOfHousehold = row.headOfHousehold;
      const unitNumber = row.unitNumber;
      const certificationType = row.certificationType;
      const certificationCompletionDate = row.certificationCompletionDate;
      const effectiveDate = row.effectiveDate;
      const status = row.status;

      return {
        checkbox: {
          value: (
            <Checkbox
              checked={checkboxList[index] ?? false}
              onChange={() => handleSelectRow(index)}
            />
          ),
        },
        headOfHousehold: {
          value: headOfHousehold,
        },
        unitNumber: {
          value: unitNumber,
        },
        certificationType: {
          value: certificationType,
        },
        effectiveDate: {
          variant: 'date',
          value: effectiveDate,
        },
        certificationCompletionDate: {
          variant: 'date',
          value: certificationCompletionDate,
        },
        status: {
          value: status,
        },
      };
    });
  }, [mincTransactions, checkboxList, handleSelectRow]);
};

export default useRows;
